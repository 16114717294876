import React, { useEffect, useState } from "react";

import { useHistory, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { SwalToast } from "components/lib/ui/toast";

import AppNavBar from "components/layout/AppNavBar";
import { get } from "networking/http";
import { GET_INSTANCE_STATUS } from "config/config";
import InstanceCardView from "components/Instances/InstanceCardView";
import LoadingSpinner from "shared/LoadingSpinner";
import Logout from "shared/Logout";

function InstanceStatusView() {
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const history = useHistory();
  const location = useLocation();

  const request = () => {
    setLoading(true);
    get(`${GET_INSTANCE_STATUS}`)
      .then(res => {
        if (res) {
          setTableData(res.data?.InstanceStatuses);
          setLoading(false);
        }
      })
      .catch(err => {
        setLoading(false);
        if (err?.response?.data && err?.response.status === 401) {
          SwalToast(
            "success",
            `Token is not valid : ${err.response.data.message}`
          );
          Logout(history, location);
        } else if (err?.response?.data) {
          Swal.fire("Something Went Wrong", err.response.data.message, "error");
        } else {
          Swal.fire("Something Went Wrong", err.message, "error");
        }
      });
  };

  useEffect(() => {
    request();
    const interval = setInterval(() => {
      request();
    }, 1000 * 60 * 10);
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <AppNavBar header="Instances Details" />
      <If condition={loading}>
        <LoadingSpinner />
      </If>
      <For each="instance" index="index" of={tableData}>
        <InstanceCardView instanceDetails={instance} key={index} />
      </For>
    </div>
  );
}

export default InstanceStatusView;
