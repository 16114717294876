import React from "react";
import PropTypes from "prop-types";

import { useHistory } from "react-router-dom";
import { Button, Nav, Navbar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { googleLogout } from '@react-oauth/google';
import Cookies from "js-cookie";

import { CLIENT_ID } from "config/config";
import {
  closeSideBar,
  handleSideBarToggle
} from "components/lib/methods/sidebarToggler";

const clientId = CLIENT_ID;

function AppNavBar({ header }) {
  const history = useHistory();

  function logout() {
    Cookies.remove("token");
    closeSideBar();
    history.push("/login");
  }

  return (
    <Navbar bg="light" expand="lg" sticky="top">
      <Button
        variant="dark"
        size="sm"
        onClick={handleSideBarToggle}
        className="mr-2 px-3"
      >
        <FontAwesomeIcon icon={faBars} />
      </Button>
      <Navbar.Brand href="/" className="font-weight-bold text-dark">
        {header}
      </Navbar.Brand>
      <Nav className="mr-auto"></Nav>
      <Button
        buttonText="Logout From App"
        variant="dark"
        size="lg"
        onClick={() => {
          googleLogout();
          logout();
        }}
      >{'Log Out'}</Button>
    </Navbar>
  );
}

AppNavBar.propTypes = {
  header: PropTypes.string.isRequired
};

export default AppNavBar;
