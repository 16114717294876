import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { Container } from "react-bootstrap";
import { css, StyleSheet } from "aphrodite";
import DataTable from "react-data-table-component";
import classnames from "classnames";

function InvoiceScraperScrapedItemsView({ items }) {
  const columns = useMemo(
    () => [
      {
        name: "Item Code",
        selector: "item_code",
        button: true,
        minWidth: "300px"
      },
      {
        name: "Quantity",
        selector: "quantity",
        sortable: true,
        maxWidth: "100px"
      },
      {
        name: "Total Price",
        selector: "total_price",
        sortable: true,
        maxWidth: "100px"
      },
      {
        name: "UOM",
        selector: "uom",
        sortable: true,
        maxWidth: "100px"
      }
    ],
    []
  );

  return (
    <div>
      <Container
        className={classnames("d-flex flex-column", css(styles.container))}
      >
        <DataTable data={items} columns={columns} fixedHeader={true} />
      </Container>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    maxWidth: "40%",
    justifyContent: "center"
  }
});

InvoiceScraperScrapedItemsView.propTypes = {
  items: PropTypes.array
};

export default InvoiceScraperScrapedItemsView;
