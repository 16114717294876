import React from "react";

import Iframe from "react-iframe";
import Cookies from "js-cookie";

import AppNavBar from "components/layout/AppNavBar";
import { GET_TASK_QUEUE } from "config/config";

function Tasks() {
  const url = GET_TASK_QUEUE + Cookies.getJSON("token")?.token;

  return (
    <div>
      <AppNavBar header="Tasks" />
      <Iframe
        url={url}
        width="100%"
        height="1000px"
        id="myId"
        className="myClassname"
        display="initial"
        position="relative"
        styles={{ backgroundColor: "red" }}
      />
    </div>
  );
}

export default Tasks;
