import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { css, StyleSheet } from "aphrodite";
import classNames from "classnames";
import DataTable from "react-data-table-component";
import dayjs from "dayjs";
import { find } from "lodash-es";
import Select from "react-select";
import Swal from "sweetalert2";
import { useHistory, useLocation, useParams } from "react-router-dom";

import AppNavBar from "components/layout/AppNavBar";
import { get } from "networking/http";
import { GET_ORDER_PLACEMENTS } from "config/config";
import { lg } from "shared/screen";
import LoadingSpinner from "shared/LoadingSpinner";
import Logout from "shared/Logout";
import PaginationBar from "shared/PaginationBar";
import useVendorMap from "components/lib/hooks/useVendorMap";
import { SwalToast } from "components/lib/ui/toast";

const paramMapping = {
  vendor_id: "vendor",
  operator_id: "operator",
  cd_order_id: "orderid",
  status_key: "statuskey"
};

function OrderPlacementsScraper() {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const vendorMap = useVendorMap();
  const [page, setPage] = useState(parseInt((params.page - 1) * 1000));
  const [activePage, setActivePage] = useState(parseInt(params.page));

  const [selectedOption, setSelectedOption] = useState(() => {
    const temp_params = { ...params };
    delete temp_params.page;
    return Object.values(temp_params)[0]
      ? {
          value: Object.keys(temp_params)[0],
          label: Object.keys(temp_params)[0]
        }
      : [];
  });

  const [searchValue, setSearchValue] = useState(() => {
    const temp_params = { ...params };
    delete temp_params.page;
    return Object.values(temp_params)[0] ? Object.values(temp_params)[0] : "";
  });

  useEffect(() => {
    const temp_params = { ...params };
    delete temp_params.page;
    const query = searchValue
      ? `${GET_ORDER_PLACEMENTS}?${Object.keys(temp_params)[0]}=${
          Object.values(temp_params)[0]
        }&offset=${page}`
      : `${GET_ORDER_PLACEMENTS}?offset=${page}`;
    setLoading(true);
    get(query)
      .then(res => {
        if (res) {
          setTableData(res.data);
          setLoading(false);
        }
      })
      .catch(err => {
        setLoading(false);
        if (err?.response?.data && err?.response.status === 401) {
          SwalToast(
            "success",
            `Token is not valid : ${err.response.data.message}`
          );
          Logout(history, location);
        } else if (err?.response?.data) {
          Swal.fire("Something Went Wrong", err.response.data.message, "error");
        } else {
          Swal.fire("Something Went Wrong", err.message, "error");
        }
      });
  }, [params.vendor_id, params.page, params.operator_id, params.status_key]);

  const vendorName = useCallback((id, channel) => {
    const data = find(vendorMap, function(o) {
      return o.value == id && o.channel == channel;
    });
    return data?.label || "";
  }, []);

  const Details = ({ label, row }) => (
    <Button
      variant="link"
      onClick={() => {
        window.open(
          `/dashboard/OrderPlacements-scraper/details/statuskey/${row.status_key}`
        );
        // history.push({pathname: `/dashboard/OrderPlacements-scraper/details/statuskey/${row.status_key}`})
      }}
    >
      {label}
    </Button>
  );

  const columns = useMemo(
    () => [
      {
        name: "Vendor Id",
        selector: "vendor_id",
        sortable: true,
        minWidth: "100px"
      },
      {
        name: "Vendor Name",
        cell: row => vendorName(row.vendor_id, row.channel),
        sortable: true
      },
      {
        name: "Status Key",
        selector: "status_key",
        sortable: true,
        minWidth: "320px"
      },
      {
        name: "Status",
        selector: "status",
        sortable: true
      },
      {
        name: "End Time",
        selector: row => dayjs(row.time).format("YYYY-MM-DDTHH:mm:ss"),
        sortable: true,
        minWidth: "180px"
      },
      {
        name: "Duration (sec)",
        selector: row => row.stats?.duration,
        minWidth: "80px",
        maxWidth: "80px"
      },
      {
        name: "CD Order Id",
        selector: "cd_order_id",
        sortable: true,
        minWidth: "120px",
        maxWidth: "120px"
      },
      {
        name: "Operator Id",
        selector: "operator_id",
        sortable: true
      },
      {
        name: "Requested/Shipped date",
        cell: row => dayjs(row.requested_date).format("YYYY-MM-DD")+" / "+dayjs(row.ship_date).format("YYYY-MM-DD"),
        minWidth: "200px",
        maxWidth: "200px"
      },
      {
        name: "Details",
        cell: row => (
          <Details label={"click for details"} row={row}>
            Action
          </Details>
        ),
        allowOverflow: true,
        button: true,
        minWidth: "200px"
      }
    ],
    []
  );

  const conditionalRowStyles = [
    {
      when: row => row.status === "order_submitted",
      style: {
        backgroundColor: "#e2efda",
        color: "#686a6c",
        "&:hover": {
          cursor: "pointer"
        }
      }
    },
    {
      when: row => row.status === "draft_saved",
      style: {
        backgroundColor: "#eec780",
        color: "#686a6c",
        "&:hover": {
          cursor: "pointer"
        }
      }
    },
    {
      when: row => row.status === "unsuccessful",
      style: {
        backgroundColor: "#efdfdf",
        color: "#686a6c",
        "&:hover": {
          cursor: "pointer"
        }
      }
    },
    {
      when: row => row.status === "progress",
      style: {
        backgroundColor: "#eec780",
        color: "#686a6c",
        "&:hover": {
          cursor: "pointer"
        }
      }
    }
  ];

  function changeActivePage(page) {
    setActivePage(page);
    const temp_params = { ...params };
    delete temp_params.page;
    if (Object.keys(temp_params).length > 0) {
      history.push({
        pathname: `/dashboard/OrderPlacements-scraper/${
          paramMapping[Object.keys(temp_params)[0]]
        }/${Object.values(temp_params)[0]}/${page}`
      });
    } else {
      history.push({ pathname: `/dashboard/OrderPlacements-scraper/${page}` });
    }
  }

  return (
    <div>
      <AppNavBar header="Order Placements" />
      <If condition={loading}>
        <LoadingSpinner />
      </If>
      <Form>
        <Row className={css(styles.searchBar)}>
          <Col sm="1" className={css(styles.label)}>
            <Select
              value={selectedOption}
              defaultOptions
              options={[
                { label: "Vendor Id", value: "vendor_id" },
                { label: "Status Key", value: "status_key" },
                { label: "Order Id", value: "cd_order_id" },
                { label: "Operator Id", value: "Operator_id" }
              ]}
              onChange={e => {
                setSelectedOption(e);
              }}
            ></Select>
          </Col>
          <Col sm="2" className={css(styles.input)}>
            <Form.Control
              size="md"
              type="text"
              placeholder={searchValue || selectedOption?.value}
              onChange={e => {
                setSearchValue(e.target.value);
              }}
            />
          </Col>
          <Col sm="2">
            <Button
              onClick={() => {
                setPage(0);
                history.push({
                  pathname: `/dashboard/OrderPlacements-scraper/${
                    paramMapping[selectedOption.value]
                  }/${searchValue}/${1}`
                });
                setActivePage(1);
              }}
            >
              {"Search"}
            </Button>
          </Col>
        </Row>
      </Form>
      <PaginationBar
        activePage={activePage}
        itemsCount={5000}
        offsetFunction={setPage}
        currentActivePage={changeActivePage}
      ></PaginationBar>
      <Container
        className={classNames(css(styles.container), "d-flex flex-column")}
      >
        <DataTable
          columns={columns}
          data={tableData}
          fixedHeader={true}
          conditionalRowStyles={conditionalRowStyles}
        />
      </Container>
      <PaginationBar
        activePage={activePage}
        itemsCount={5000}
        offsetFunction={setPage}
        currentActivePage={changeActivePage}
      ></PaginationBar>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    maxWidth: lg() ? "100%" : "100%"
  },
  searchBar: {
    justifyContent: "center",
    paddingTop: "3rem"
  },
  input: {
    paddingLeft: "1rem"
  },
  label: {
    paddingRight: "0rem",
    textAlign: "right"
  }
});

export default OrderPlacementsScraper;
