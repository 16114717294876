import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";

import { Container, Badge } from "react-bootstrap";
import { css, StyleSheet } from "aphrodite";
import DataTable from "react-data-table-component";
import classnames from "classnames";

function OrderPlacementStatsView({ stats, exceptions }) {
  const [statItems, setStatItems] = useState(stats[0].items);

  useMemo(() => {
    if (exceptions.length > 0) {
      const unavailableItems = [];

      exceptions.map(e => {
        if (e.reason == "unavailable") {
          unavailableItems.push({
            item_code: e.item_code,
            reason: "unavailable"
          });
        }
        stats[0].items.map(o => {
          if (e.item_code == o.item_code) {
            o.reason = e.reason;
          }
        });
      });
      setStatItems(unavailableItems.concat(stats[0].items));
    }
  }, [stats]);

  const Details = ({ row }) => (
    <div>
      <Choose>
        <When condition={row.reason}>
          <div>
            {row.item_code}
            <Badge variant="success" className={css(styles.exceptionbadge)}>
              {row.reason}
            </Badge>
          </div>
        </When>
        <Otherwise>{row.item_code}</Otherwise>
      </Choose>
    </div>
  );

  const columns = useMemo(
    () => [
      {
        name: "Details",
        cell: row => <Details row={row}>Action</Details>,
        allowOverflow: true,
        button: true,
        minWidth: "300px"
      },
      {
        name: "Quantity",
        selector: "quantity",
        sortable: true,
        maxWidth: "100px"
      },
      {
        name: "Total Price",
        selector: "total_price",
        sortable: true,
        maxWidth: "100px"
      },
      {
        name: "UOM",
        selector: "uom",
        sortable: true,
        maxWidth: "100px"
      }
    ],
    []
  );

  return (
    <div>
      <Badge variant="success" className={css(styles.badges)}>
        Order Number: {stats[0].order_no}
      </Badge>
      <Badge variant="success" className={css(styles.badges)}>
        Delivery Date: {stats[0].delivery_date}
      </Badge>
      <Badge variant="success" className={css(styles.badges)}>
        Total Price: {stats[0].total_price}
      </Badge>
      <Container
        className={classnames("d-flex flex-column", css(styles.container))}
      >
        <DataTable data={statItems} columns={columns} fixedHeader={true} />
      </Container>
    </div>
  );
}

const styles = StyleSheet.create({
  badges: {
    marginTop: "1rem",
    fontSize: 20,
    marginRight: "10px",
    marginLeft: "10px",
    background: "#48CFAD"
  },
  exceptionbadge: {
    marginLeft: "3px",
    background: "#efdfdf",
    color: "#686a6c"
  },
  container: {
    maxWidth: "40%",
    justifyContent: "center"
  }
});

OrderPlacementStatsView.propTypes = {
  stats: PropTypes.array,
  exceptions: PropTypes.array
};

export default OrderPlacementStatsView;
