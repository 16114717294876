import { isLocalhost, isDevServer } from "serviceWorker";

let SERVER = "https://scraper-dashboard-api.cutanddry.com/api/v1";
let CLIENT_ID =
  "210453596776-67ajb6ieoj280dis65sigs62i1dkf2om.apps.googleusercontent.com";
let INSTANCES = {
  // orders
  "i-0874377dae049d180": "scraper-placeorder1",
  "i-0f1d34ab1c53ab1bf": "scraper-placeorder3",
  // orderguides
  "i-0a699931eb81ee869": "scraper-orderguides2",
  "i-030d30b2e4717c09f": "scraper-orderguides3",
  "i-08a5afe13bca4d702": "scraper-orderguides4",
  // invoices
  "i-01806cca1a91f1435": "scraper-invoices1",
  "i-0588a04b066f07d47": "scraper-invoices2",
  // catalogs
  "i-07913be2e3cf1f918": "scraper-productcatalog1",
  // express login
  "i-01fa8cd1c9d985a02": "scraper-express-login-1",
  "i-0ef2bb8924d7f0f41": "scraper-express-login-2",
};
if (isLocalhost) {
  INSTANCES = {
    // "i-054c09d1b625490c2": "ocr_backend_dev"
    "i-074959107f58200e4": "scraper-dev",
  };
  SERVER = "http://localhost:5000/api/v1";
  CLIENT_ID =
    "210453596776-ss01vldbr06lb0u2r7q6ls6hd71rd055.apps.googleusercontent.com";
}

if (isDevServer) {
  SERVER = "http://18.188.135.202:5000/api/v1";
  CLIENT_ID =
    "210453596776-ss01vldbr06lb0u2r7q6ls6hd71rd055.apps.googleusercontent.com";
  INSTANCES = {
    "i-074959107f58200e4": "scraper-dev",
  };
}

const CLOUD_WATCH_URL =
  "https://us-east-2.console.aws.amazon.com/cloudwatch/\
home?region=us-east-2#logEventViewer:group=codify_sl;stream=scraper;filter=%22";
const SCRAPE_ERROR_PNG =
  "https://scraping-errors.s3.us-east-2.amazonaws.com/scraping/screenshots/";
const SCRAPE_ERROR_FILE =
  "https://scraping-errors.s3.us-east-2.amazonaws.com/scraping/files/";
const ORDER_CONFIRMATION =
  "https://scraping-orders.s3.us-east-2.amazonaws.com/order_confirmation/screenshots/";
const ORDER_FILLED =
  "https://scraping-orders.s3.us-east-2.amazonaws.com/filled_order/screenshots/";
const ORDER_DELIVERY_DATES =
  "https://scraping-orders.s3.us-east-2.amazonaws.com/delivery_dates/screenshots/";
const ORDER_ERROR_PNG =
  "https://scraping-errors.s3.us-east-2.amazonaws.com/ordering/screenshots/";
const ORDER_ERROR_FILE =
  "https://scraping-errors.s3.us-east-2.amazonaws.com/ordering/files/";
const ORDER_GUIDES_DELIVERY_DATES_PNG =
  "https://scraping-assets.s3.us-east-2.amazonaws.com/order_guides/delivery_dates/screenshots/";
const INVOICE_ERROR_PNG =
  "https://scraping-errors.s3.us-east-2.amazonaws.com/scraping/screenshots/";
const INVOICE_ERROR_FILE =
  "https://scraping-errors.s3.us-east-2.amazonaws.com/scraping/files/";

const GET_CATALOG_SCRAPE_DETAILS = `${SERVER}/catalog_scrapes`;
const GET_CATELOG_FOR_VENDOR = `${SERVER}/catalogs?vendor_id=`;
const GET_CATELOG_DETAILS = `${SERVER}/catalog_details`;
const GET_CATELOG_DETAILS_ITEM = `${SERVER}/catalog_details/item?vendor_id=`;
const POST_CATALOG_ITEM = `${SERVER}/update_catalog_item`;
const POST_PUSH_CATELOG_ITEM = `${SERVER}/dashboard/push_catalog_item?vendor_id=`;
const GET_LOGIN = `${SERVER}/dashboard/login`;
const GET_ORDER_GUIDE = `${SERVER}/order_guides?vendor_id=`;
const GET_ALL_ORDER_GUIDES = `${SERVER}/order_guides_all`;
const GET_SINGLE_ORDER_GUIDE_SCRAPE_DETAILS = `${SERVER}/order_guides_status?status_key=`;
const GET_ORDER_GUIDE_ITEMS = `${SERVER}/order_guides_items?id=`;
const GET_ORDER_GUIDES_BULK = `${SERVER}/order_guides_bulk`;
const POST_SCRAPER_STATUS_CHANGE = `${SERVER}/dashboard/change_status`;
const GET_ORDER_PLACEMENTS = `${SERVER}/order_placements`;
const GET_INSTANCE_STATUS = `${SERVER}/dashboard/instance_status`;
const GET_REBOOT_INSTANCE = `${SERVER}/dashboard/reboot_instance?instance_id=`;
const GET_TASK_QUEUE = `${SERVER}/dashboard/rq/?access_token=`;
const GET_ALL_INVOICES = `${SERVER}/dashboard/invoices_all`;
const GET_SINGLE_INVOICE_SCRAPE_DETAILS = `${SERVER}/dashboard/invoice_status?status_key=`;
const GET_CRON_JOBS = `${SERVER}/dashboard/cron_jobs`;
const GET_SCREENS = `${SERVER}/dashboard/screens`;
const POST_CREATE_A_SCREEN = `${SERVER}/dashboard/screen_run`;
const POST_DELETE_A_SCREEN = `${SERVER}/dashboard/delete_a_screen`;

const ITEM_STATUS = [
  { value: "ITEM_STATUS_AVAILABLE", label: "available" },
  { value: "ITEM_STATUS_OUT_OF_STOCK", label: "not available" },
  { value: "ITEM_STATUS_PHASED_OUT", label: "phased out" },
];

const CATALOG_SCRAPES = [
  { value: "Birite", label: "birite_catalog" },
  { value: "US Foods", label: "usfoods_catalog" },
  { value: "Webstaurant Store", label: "webstaurant_catalog" },
];

export {
  SERVER,
  CLIENT_ID,
  INSTANCES,
  CLOUD_WATCH_URL,
  SCRAPE_ERROR_PNG,
  SCRAPE_ERROR_FILE,
  ORDER_CONFIRMATION,
  ORDER_FILLED,
  ORDER_DELIVERY_DATES,
  ORDER_ERROR_PNG,
  ORDER_ERROR_FILE,
  ORDER_GUIDES_DELIVERY_DATES_PNG,
  INVOICE_ERROR_PNG,
  INVOICE_ERROR_FILE,
  GET_CATALOG_SCRAPE_DETAILS,
  GET_CATELOG_FOR_VENDOR,
  GET_CATELOG_DETAILS,
  GET_CATELOG_DETAILS_ITEM,
  POST_CATALOG_ITEM,
  POST_PUSH_CATELOG_ITEM,
  GET_LOGIN,
  GET_ORDER_GUIDE,
  GET_INSTANCE_STATUS,
  GET_REBOOT_INSTANCE,
  GET_ALL_ORDER_GUIDES,
  GET_SINGLE_ORDER_GUIDE_SCRAPE_DETAILS,
  GET_ORDER_GUIDE_ITEMS,
  GET_ORDER_GUIDES_BULK,
  POST_SCRAPER_STATUS_CHANGE,
  GET_ORDER_PLACEMENTS,
  GET_TASK_QUEUE,
  ITEM_STATUS,
  GET_ALL_INVOICES,
  GET_SINGLE_INVOICE_SCRAPE_DETAILS,
  GET_CRON_JOBS,
  CATALOG_SCRAPES,
  GET_SCREENS,
  POST_CREATE_A_SCREEN,
  POST_DELETE_A_SCREEN,
};
