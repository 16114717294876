import React, { useEffect, useMemo, useState } from "react";

import { Button, Card, Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import { css, StyleSheet } from "aphrodite";
import DataTable from "react-data-table-component";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
import { useHistory, useLocation, useParams } from "react-router-dom";

import AppNavBar from "components/layout/AppNavBar";
import ExpandedComponent from "components/scraper/OrderGuide/ExpandedComponent";
import { get } from "networking/http";
import {
  GET_ORDER_GUIDE_ITEMS,
  ORDER_GUIDES_DELIVERY_DATES_PNG
} from "config/config";
import LoadingSpinner from "shared/LoadingSpinner";
import Logout from "shared/Logout";
import SearchBar from "components/scraper/OrderGuide/SearchBar";
import { SwalToast } from "components/lib/ui/toast";

function OrderGuideScrapeItemsView({}) {
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState();
  const history = useHistory();
  const location = useLocation();

  const [filteredItems, setFilteredItems] = useState();
  const [filteredText, setFilteredtext] = useState("");
  const [filteredCode, setFilteredCode] = useState("");
  const params = useParams();
  const [deliveryDates, setDeliveryDates] = useState([]);

  useEffect(() => {
    setLoading(true);
    get(`${GET_ORDER_GUIDE_ITEMS}${params?.object_id}`)
      .then(res => {
        if (res) {
          setTableData(res.data);
          setLoading(false);
        }
      })
      .catch(err => {
        setLoading(false);
        if (err?.response?.data && err?.response.status === 401) {
          SwalToast(
            "success",
            `Token is not valid : ${err.response.data.message}`
          );
          Logout(history, location);
        } else if (err?.response?.data) {
          Swal.fire("Something Went Wrong", err.response.data.message, "error");
        } else {
          Swal.fire("Something Went Wrong", err.message, "error");
        }
      });
  }, []);

  useEffect(() => {
    if (tableData?.available_delivery_dates) {
      setDeliveryDates(
        tableData.available_delivery_dates.map(d => new Date(d))
      );
    }
  }, [tableData]);

  const columns = useMemo(
    () => [
      {
        name: "Item Code",
        selector: "item_code",
        sortable: true
      },
      {
        name: "Product Name",
        selector: "product_name",
        sortable: true
      },
      {
        name: "Category",
        selector: "category",
        sortable: true
      },
      {
        name: "UOM",
        selector: "uom",
        sortable: true
      },
      {
        name: "Image URL",
        selector: "original_image_url",
        cell: d => (
          <img
            height="32x"
            width="64px"
            alt={"no-image"}
            src={"http://" + d.original_image_url}
          />
        )
      }
    ],
    []
  );

  useEffect(() => {
    const fil = tableData?.items.filter(
      item =>
        item.product_name &&
        item.product_name.toLowerCase().includes(filteredText.toLowerCase())
    );
    setFilteredItems(fil);
  }, [filteredText]);

  useEffect(() => {
    const fil = tableData?.items.filter(
      item =>
        item.product_name &&
        item.item_code.toLowerCase().includes(filteredCode.toLowerCase())
    );
    setFilteredItems(fil);
  }, [filteredCode]);

  return (
    <div>
      <AppNavBar header="Order Guide Scraper" />
      <SearchBar
        orderGuideName={tableData?.order_guide_name}
        operatorId={tableData?.operator_id}
        vendorId={tableData?.vendor_id}
      />
      <If condition={loading}>
        <LoadingSpinner />
      </If>
      <Tabs
        defaultActiveKey="main"
        id="uncontrolled-tab-example"
        className={css(styles.tab)}
      >
        <Tab eventKey="main" title="Items Details">
          <Form>
            <Row className={css(styles.searchBar)}>
              <Col sm="1" className={css(styles.label)}>
                <Form.Label className="font-weight-bold text-muted">
                  {"Item Name : "}
                </Form.Label>
              </Col>
              <Col sm="2" className={css(styles.input)}>
                <Form.Control
                  size="sm"
                  type="text"
                  placeholder="Enter Product Name"
                  value={filteredText}
                  onChange={e => {
                    setFilteredtext(e.target.value);
                  }}
                />
              </Col>
              <Col sm="1" className={css(styles.clear)}>
                <Button
                  variant="info"
                  size="sm"
                  onClick={() => {
                    setFilteredtext("");
                  }}
                >
                  {"X"}
                </Button>
              </Col>
              <Col sm="1" className={css(styles.label)}>
                <Form.Label className="font-weight-bold text-muted">
                  {"Item Code : "}
                </Form.Label>
              </Col>
              <Col sm="2" className={css(styles.input)}>
                <Form.Control
                  size="sm"
                  type="text"
                  placeholder="Enter Product Code"
                  value={filteredCode}
                  onChange={e => {
                    setFilteredCode(e.target.value);
                  }}
                />
              </Col>
              <Col sm="1" className={css(styles.clear)}>
                <Button
                  variant="info"
                  size="sm"
                  onClick={() => {
                    setFilteredCode("");
                  }}
                >
                  {"X"}
                </Button>
              </Col>
            </Row>
          </Form>
          <DataTable
            columns={columns}
            data={filteredItems || tableData?.items}
            expandableRows
            expandableRowsComponent={<ExpandedComponent />}
            expandOnRowClicked
            fixedHeader={true}
            paginationPerPage={100}
            paginationRowsPerPageOptions={[100, 150, 200]}
            pagination
          />
        </Tab>
        <Tab eventKey="secondary" title="Delivery Dates">
          <div className={css(styles.tab)}>
            <Row>
              <DatePicker
                className={css(styles.cardTitle)}
                selected={null}
                includeDates={deliveryDates}
                highlightDates={deliveryDates}
                inline
              />
              {/* Only for PFG */}
              <If condition={tableData?.vendor_id === "1843976"}>
                <Card className={css(styles.cards)}>
                  <a
                    href={
                      ORDER_GUIDES_DELIVERY_DATES_PNG +
                      tableData?.status_key +
                      ".png"
                    }
                    target="_blank"
                  >
                    <Card.Img
                      src={
                        ORDER_GUIDES_DELIVERY_DATES_PNG +
                        tableData?.status_key +
                        ".png"
                      }
                      variant="top"
                    />
                  </a>
                  <Card.Body>
                    <Card.Title>{"Delivery Dates Page"}</Card.Title>
                    <Card.Text>{tableData?.message}</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <small className="text-muted">{tableData?.time}</small>
                  </Card.Footer>
                </Card>
              </If>
              <If condition={tableData?.vendor_id !== "1843976"}>
                <Card className={css(styles.cards)}>
                  <Card.Body>
                    <Card.Title>
                      <div className={css(styles.centerText)}>
                        <pre>Delivery Dates Screenshot</pre>
                        <pre>Not Available</pre>
                      </div>
                    </Card.Title>
                  </Card.Body>
                </Card>
              </If>
            </Row>
          </div>
        </Tab>
      </Tabs>
    </div>
  );
}

const styles = StyleSheet.create({
  searchBar: {
    justifyContent: "center",
    paddingTop: "3rem"
  },
  label: {
    paddingRight: "0rem",
    textAlign: "right"
  },
  input: {
    paddingLeft: "1rem",
    paddingRight: "0rem"
  },
  tab: {
    marginTop: "2rem"
  },
  cardTitle: {
    marginTop: "2rem",
    marginBottom: "2rem"
  },
  clear: {
    float: "right",
    paddingLeft: "0rem",
    height: "3rem",
    maxWidth: "1rem"
  },
  cards: {
    width: "20rem",
    marginRight: "2rem",
    marginLeft: "2rem"
  },
  centerText: {
    textAlign: "center"
  }
});

export default OrderGuideScrapeItemsView;
