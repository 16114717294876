import React, { useState } from "react";
import PropTypes from "prop-types";

import { Button, Card, Col, Row } from "react-bootstrap";
import classnames from "classnames";
import { css, StyleSheet } from "aphrodite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMedkit } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { SwalToast } from "components/lib/ui/toast";

import { GET_REBOOT_INSTANCE, INSTANCES } from "config/config";
import Logout from "shared/Logout";
import { post } from "networking/http";

function InstanceCardView({ instanceDetails }) {
  const [loading, setLoading] = useState(false);
  const status =
    instanceDetails?.InstanceStatus.Status === "ok" &&
    instanceDetails?.SystemStatus.Status === "ok"
      ? "#48CFAD"
      : "#ED5565";
  const history = useHistory();

  function reboot_instance(id) {
    setLoading(true);
    post(`${GET_REBOOT_INSTANCE}${id}`)
      .then(res => {
        if (res) {
          SwalToast("success", `Succesfully Rebooted Instance ${id}`);
          setLoading(false);
        }
      })
      .catch(err => {
        setLoading(false);
        if (err?.response?.data && err?.response.status === 401) {
          SwalToast(
            "success",
            `Token is not valid : ${err.response.data.message}`
          );
          Logout(history, location);
        } else if (err?.response?.data) {
          Swal.fire("Something Went Wrong", err.response.data.message, "error");
        } else {
          Swal.fire("Something Went Wrong", err.message, "error");
        }
      });
  }

  return (
    <div>
      <Card className={classnames("text-center", css(styles.cards))}>
        <Row style={{ justifyContent: "center" }}>
          <Col md="auto">
            <FontAwesomeIcon
              icon={faMedkit}
              className="fa-10x"
              color={status}
            />
          </Col>
        </Row>
        <Card.Body>
          <Card.Title>{"Health Checks"}</Card.Title>
          <Card.Text>
            {"Instance Id : "}
            <span style={{ color: status, fontWeight: "bold" }}>
              {instanceDetails?.InstanceId}
            </span>
          </Card.Text>
          <Card.Text>
            {"Instance Name : "}
            <span style={{ color: status, fontWeight: "bold" }}>
              {INSTANCES[instanceDetails?.InstanceId]}
            </span>
          </Card.Text>
          <Card.Text>
            {"Instance State : "}
            <span style={{ color: status, fontWeight: "bold" }}>
              {instanceDetails?.InstanceState.Name}
            </span>
          </Card.Text>
          <Card.Text>
            {"Instance Status : "}
            <span style={{ color: status, fontWeight: "bold" }}>
              {instanceDetails?.InstanceStatus.Status}
            </span>
          </Card.Text>
          <Card.Text>
            {"System Status : "}
            <span style={{ color: status, fontWeight: "bold" }}>
              {instanceDetails?.SystemStatus.Status}
            </span>
          </Card.Text>
          <Choose>
            <When condition={loading}>
              <Button variant="success">{"Rebooting"}</Button>
            </When>
            <Otherwise>
              <Button
                variant="primary"
                onClick={() => {
                  reboot_instance(instanceDetails?.InstanceId);
                }}
              >
                {"Reboot"}
              </Button>
            </Otherwise>
          </Choose>
        </Card.Body>
      </Card>
    </div>
  );
}

const styles = StyleSheet.create({
  cards: {
    width: "20rem",
    float: "left",
    marginRight: "2rem",
    marginTop: "2rem",
    background: "#ebeef2"
  }
});

InstanceCardView.propTypes = {
  instanceDetails: PropTypes.object
};

export default InstanceCardView;
