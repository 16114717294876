const sizes = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200
};
const MAX_PHONE_SIZE = sizes.md - 1;
const MAX_TABLET_SIZE = sizes.lg - 1;

const xsAndBelow = (styles = null) => {
  if (styles === null) {
    return window.matchMedia("(max-width: 767px)").matches;
  }
  return { "@media (max-width: 767px)": styles };
};

const mdAndAbove = (styles = null) => {
  if (styles === null) {
    return window.matchMedia("(min-width: 768px)").matches;
  }
  return { "@media (min-width: 768px)": styles };
};

function isPhoneSize() {
  return window.innerWidth <= MAX_PHONE_SIZE;
}

function screenSizeDefiner(size, styles) {
  if (!styles) {
    return window.matchMedia(`(min-width: ${size}px)`).matches;
  }
  return { [`@media (min-width: ${size}px)`]: styles };
}

const xs = styles => screenSizeDefiner(sizes.xs, styles);
const sm = styles => screenSizeDefiner(sizes.sm, styles);
const md = styles => screenSizeDefiner(sizes.md, styles);
const lg = styles => screenSizeDefiner(sizes.lg, styles);
const xl = styles => screenSizeDefiner(sizes.xl, styles);

export default {
  mdAndAbove,
  xsAndBelow
};

export {
  isPhoneSize,
  MAX_PHONE_SIZE,
  MAX_TABLET_SIZE,
  xs,
  sm,
  md,
  lg,
  xl,
  sizes as screenSizes
};
