import React, { useContext, useState } from "react";

import { Card, Col, Nav, Row } from "react-bootstrap";
import classnames from "classnames";
import { css, StyleSheet } from "aphrodite";
import {
  faHome,
  faSearch,
  faAngleDown,
  faCalendarMinus,
  faFileInvoiceDollar,
  faVial,
  faBook,
  faTasks,
  faBookOpen,
  faHourglassEnd,
  faNewspaper
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { AuthContext } from "components/lib/auth";
import background from "asset/images/userBackground.jpeg";
import { lg } from "shared/screen";

function AppNavSideBar() {
  const [authToken] = useContext(AuthContext);
  const [scrperDropdown, setScrperDropdown] = useState(false);

  return (
    <nav
      id="sidebar"
      className={classnames("sidebar-wrapper", css(styles.bar))}
    >
      <div>
        <Card className={css(styles.card)} text={"white"}>
          <Card.Header className={css(styles.header)}>
            {"Scraper Dashboard"}
          </Card.Header>
          <Card.Body className={css(styles.row)}>
            <Choose>
              <When condition={lg()}>
                <Row style={{ justifyContent: "center" }}>
                  <Col sm="8">
                    <Card.Img
                      className={css(styles.image)}
                      variant="top"
                      src={authToken?.imgUrl}
                    />
                  </Col>
                </Row>
              </When>
            </Choose>
            <Row style={{ justifyContent: "center" }}>
              <Col sm="6" className={css(styles.userData)}>
                <span>{authToken?.name}</span>
                <br></br>
                <span>{authToken?.accessLevel}</span>
              </Col>
            </Row>
          </Card.Body>
          <Card.Body className={css(styles.cardBody)}>
            <Card.Title className={css(styles.title)}>
              {"Dashboard Panels"}
            </Card.Title>
            <Nav defaultActiveKey="/dashboard/home" className="flex-column">
              <Nav.Link
                eventKey={"home"}
                href="/dashboard/home"
                className={css(styles.links)}
              >
                <FontAwesomeIcon icon={faHome} className={css(styles.icons)} />
                {"Home"}
              </Nav.Link>
              <Nav.Link
                eventKey="scrape"
                className={css(styles.links)}
                onClick={() => {
                  setScrperDropdown(!scrperDropdown);
                }}
              >
                <FontAwesomeIcon
                  icon={faSearch}
                  className={css(styles.icons)}
                />
                {"Scrape "}
                <FontAwesomeIcon icon={faAngleDown} rotation={270} />
              </Nav.Link>
              <If condition={scrperDropdown}>
                <Nav.Link
                  href="/dashboard/orderguide-scraper/1"
                  className={css(styles.subLink)}
                  eventKey="scrape_1"
                >
                  <FontAwesomeIcon
                    icon={faCalendarMinus}
                    className={css(styles.icons)}
                  />
                  {"OG Scrapes"}
                </Nav.Link>
                <Nav.Link
                  href="/dashboard/invoice-scraper/1"
                  className={css(styles.subLink)}
                  eventKey="scrape_2"
                >
                  <FontAwesomeIcon
                    icon={faFileInvoiceDollar}
                    className={css(styles.icons)}
                  />
                  {"Invoice Scrapes"}
                </Nav.Link>
                <Nav.Link
                  href="/dashboard/catalog-scraper"
                  className={css(styles.subLink)}
                  eventKey="catalog-scrape"
                >
                  <FontAwesomeIcon
                    icon={faBook}
                    className={css(styles.icons)}
                  />
                  {"Catalog Scrapes"}
                </Nav.Link>
                <Nav.Link
                  href="/dashboard/OrderPlacements-scraper/1"
                  className={css(styles.subLink)}
                  eventKey="scrape"
                >
                  <FontAwesomeIcon
                    icon={faBook}
                    className={css(styles.icons)}
                  />
                  {"Order Placememnts"}
                </Nav.Link>
              </If>
              <Nav.Link
                href="/dashboard/catalogs"
                className={css(styles.links)}
              >
                <FontAwesomeIcon
                  icon={faBookOpen}
                  className={css(styles.icons)}
                />
                {"Catalogs"}
              </Nav.Link>
              <Nav.Link
                href="/dashboard/integrations"
                className={css(styles.links)}
              >
                <FontAwesomeIcon icon={faVial} className={css(styles.icons)} />
                {"Integration Tests"}
              </Nav.Link>
              <Nav.Link
                href="/dashboard/instances"
                className={css(styles.links)}
                eventKey="instances"
              >
                <FontAwesomeIcon icon={faBook} className={css(styles.icons)} />
                {"Instance Status"}
              </Nav.Link>
              <Nav.Link
                href="/dashboard/cron_jobs"
                className={css(styles.links)}
                eventKey="cronJobs"
              >
                <FontAwesomeIcon
                  icon={faHourglassEnd}
                  className={css(styles.icons)}
                />
                {"Cron Jobs"}
              </Nav.Link>
              <Nav.Link
                href="/dashboard/catalogs_runs"
                className={css(styles.links)}
                eventKey="catalogsRuns"
              >
                <FontAwesomeIcon
                  icon={faNewspaper}
                  className={css(styles.icons)}
                />
                {"Create A Catalog Scrape"}
              </Nav.Link>
              <Nav.Link
                href="/dashboard/tasks"
                className={css(styles.links)}
                eventKey="tasks"
              >
                <FontAwesomeIcon icon={faTasks} className={css(styles.icons)} />
                {"Task Details"}
              </Nav.Link>
            </Nav>
          </Card.Body>
        </Card>
      </div>
    </nav>
  );
}

const styles = StyleSheet.create({
  header: {
    fontFamily: "Dense",
    fontSize: 25,
    letterSpacing: 1,
    justifyContent: "center",
    textAlign: "center"
  },
  links: {
    color: "white",
    fontFamily: "Orbitron",
    fontSize: 18
  },
  subLink: {
    marginLeft: "20px",
    height: "100%",
    color: "white",
    fontFamily: "Orbitron",
    fontSize: 18
  },
  cardBody: {
    height: "100vh"
  },
  card: {
    backgroundColor: "#2d3a4a"
  },
  bar: {
    height: "100vh",
    overflowY: "auto"
  },
  image: {
    borderRadius: "50px"
  },
  row: {
    backgroundImage: "url(" + background + ")"
  },
  userData: {
    fontFamily: "Orbitron",
    fontSize: 20,
    letterSpacing: 1,
    justifyContent: "center",
    textAlign: "center",
    marginTop: "10px"
  },
  title: {
    fontFamily: "Orbitron",
    fontSize: 22,
    letterSpacing: 1,
    justifyContent: "center",
    textAlign: "center"
  },
  icons: {
    marginRight: "1rem",
    paddingLeft: "0rem"
  }
});

export default AppNavSideBar;
