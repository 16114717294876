import Swal from "sweetalert2";

const SwalToast = (type, message, position) => {
  Swal.fire({
    position: position ? position : "top-middle",
    icon: type,
    text: message,
    toast: true,
    showConfirmButton: false,
    timer: 3000
  });
};

export { SwalToast };
