import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Container } from "react-bootstrap";
import classNames from "classnames";
import { css, StyleSheet } from "aphrodite";
import DataTable from "react-data-table-component";
import dayjs from "dayjs";
import { find } from "lodash-es";
import Swal from "sweetalert2";
import { SwalToast } from "components/lib/ui/toast";
import { useHistory, useLocation } from "react-router";

import AppNavBar from "components/layout/AppNavBar";
import { get } from "networking/http";
import { GET_CATALOG_SCRAPE_DETAILS } from "config/config";
import { lg } from "shared/screen";
import LoadingSpinner from "shared/LoadingSpinner";
import Logout from "shared/Logout";
import useVendorMap from "components/lib/hooks/useVendorMap";

const scraper_status = {
  1: "RUNNING",
  2: "FINISHED",
  3: "FAILED"
};

function CatalogScraper() {
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const vendorMap = useVendorMap();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    setLoading(true);
    get(`${GET_CATALOG_SCRAPE_DETAILS}`)
      .then(res => {
        if (res) {
          setTableData(res.data);
          setLoading(false);
        }
      })
      .catch(err => {
        setLoading(false);
        if (err?.response?.data && err?.response.status === 401) {
          SwalToast(
            "success",
            `Token is not valid : ${err.response.data.message}`
          );
          Logout(history, location);
        } else if (err?.response?.data) {
          Swal.fire("Something Went Wrong", err.response.data.message, "error");
        } else {
          Swal.fire("Something Went Wrong", err.message, "error");
        }
      });
  }, []);

  const vendorName = useCallback((id, channel) => {
    const data = find(vendorMap, function(o) {
      return o.value == id && o.channel == channel;
    });
    return data?.label || "";
  }, []);

  const columns = useMemo(
    () => [
      {
        name: "Vendor Id",
        selector: "vendor_id",
        sortable: true
      },
      {
        name: "Vendor Name",
        cell: row => vendorName(row.vendor_id, row.channel),
        sortable: true
      },
      {
        name: "Status",
        selector: "product_name",
        cell: row => scraper_status[row.status],
        sortable: true
      },
      {
        name: "Scraped Item Count",
        selector: "scraped_item_count",
        sortable: true
      },
      {
        name: "Phased Out Items count",
        selector: "phased_out_item_count",
        sortable: true
      },
      {
        name: "Started Time",
        cell: row => dayjs.unix(row.start_ts).format("YYYY-MM-DDTHH:mm:ss"),
        sortable: true
      },
      {
        name: "Ended time",
        cell: row => dayjs.unix(row.end_ts).format("YYYY-MM-DDTHH:mm:ss"),
        sortable: true
      }
    ],
    []
  );

  const conditionalRowStyles = [
    {
      when: row => row.status === 2,
      style: {
        backgroundColor: "#e2efda",
        color: "#686a6c",
        "&:hover": {
          cursor: "pointer"
        }
      }
    },
    {
      when: row => row.status === 3,
      style: {
        backgroundColor: "#efdfdf",
        color: "#686a6c",
        "&:hover": {
          cursor: "pointer"
        }
      }
    },
    {
      when: row => row.status === 1,
      style: {
        backgroundColor: "#eec780",
        color: "#686a6c",
        "&:hover": {
          cursor: "pointer"
        }
      }
    }
  ];

  return (
    <div>
      <AppNavBar header="Catalog Scraper" />
      <Container
        className={classNames(css(styles.container), "d-flex flex-column")}
      >
        <If condition={loading}>
          <LoadingSpinner />
        </If>
        <DataTable
          columns={columns}
          data={tableData}
          fixedHeader={true}
          paginationPerPage={100}
          paginationRowsPerPageOptions={[100, 150, 200]}
          pagination
          conditionalRowStyles={conditionalRowStyles}
        />
      </Container>
    </div>
  );
}

const styles = StyleSheet.create({
  table: {
    marginTop: 15
  },
  container: {
    maxWidth: lg() ? "70%" : "100%"
  },
  tabs: {
    marginTop: "2rem"
  }
});

export default CatalogScraper;
