import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { css, StyleSheet } from "aphrodite";
import { Badge, Col, Row } from "react-bootstrap";
import { find } from "lodash-es";

import useVendorMap from "components/lib/hooks/useVendorMap";

function SearchBar({ orderGuideName, operatorId, vendorId }) {
  const vendorMap = useVendorMap();

  const vendorName = useMemo(() => {
    const data = find(vendorMap, function(o) {
      return o.value == vendorId;
    });
    return data?.label || "";
  }, [vendorId]);

  return (
    <div className={css(styles.searchBar)}>
      <Row>
        <Col sm="8">
          <Badge variant="success" className={css(styles.orderGuideBadge)}>
            Order Guide Name: {orderGuideName}
          </Badge>
          <Badge variant="success" className={css(styles.vendorBadge)}>
            Vendor : {vendorName}
          </Badge>
          <Badge variant="success" className={css(styles.operatorBadge)}>
            Operator : {vendorId}
          </Badge>
        </Col>
      </Row>
    </div>
  );
}

const styles = StyleSheet.create({
  searchBar: {
    marginTop: 10
  },
  orderGuideBadge: {
    fontSize: 20,
    marginRight: "10px",
    marginLeft: "10px",
    background: "#48CFAD"
  },
  vendorBadge: {
    fontSize: 20,
    marginRight: "10px",
    marginLeft: "10px",
    background: "#4FC1E9"
  },
  operatorBadge: {
    fontSize: 20,
    marginRight: "10px",
    marginLeft: "10px",
    background: "#AC92EC"
  }
});

SearchBar.propTypes = {
  orderGuideName: PropTypes.string,
  operatorId: PropTypes.string,
  vendorId: PropTypes.string
};

export default SearchBar;
