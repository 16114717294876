import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";

import { Container, Badge } from "react-bootstrap";
import { css, StyleSheet } from "aphrodite";
import DataTable from "react-data-table-component";
import classnames from "classnames";

function OrderPlacementRequestView({ data }) {
  const columns = useMemo(
    () => [
      {
        name: "Item Code",
        selector: "item_code",
        sortable: true
      },
      {
        name: "Quantity",
        selector: "qty",
        sortable: true
      },
      {
        name: "UOM",
        selector: "uom",
        sortable: true
      }
    ],
    []
  );

  return (
    <div>
      <Badge variant="success" className={css(styles.badges)}>
        Delivery Date: {data.delivery_date}
      </Badge>
      <Badge variant="success" className={css(styles.badges)}>
        Location Id: {data.location_id}
      </Badge>
      <Badge variant="success" className={css(styles.badges)}>
        Order Guide Id: {data.order_guide_id}
      </Badge>
      <Container
        className={classnames("d-flex flex-column", css(styles.container))}
      >
        <DataTable data={data.items} columns={columns} fixedHeader={true} />
      </Container>
    </div>
  );
}

const styles = StyleSheet.create({
  badges: {
    marginTop: "1rem",
    fontSize: 20,
    marginRight: "10px",
    marginLeft: "10px",
    background: "#48CFAD"
  },
  exceptionbadge: {
    marginLeft: "3px",
    background: "#efdfdf",
    color: "#686a6c"
  },
  container: {
    maxWidth: "40%",
    justifyContent: "center"
  }
});

OrderPlacementRequestView.propTypes = {
  data: PropTypes.object
};

export default OrderPlacementRequestView;
