import axios from "axios";
import Cookies from "js-cookie";
import { isEmpty } from "lodash";

axios.defaults.headers.common["x-access-token"] = Cookies.getJSON(
  "token"
)?.token;

const get = (URL, headers = {}) => {
  if (isEmpty(headers)) {
    headers = { "x-access-token": Cookies.getJSON("token")?.token };
  }
  return axios.get(URL, { responseType: "json", headers: headers });
};

const post = (URL, data) => {
  const headers = { "x-access-token": Cookies.getJSON("token")?.token };
  return axios.post(URL, data, { withCredentials: true, headers: headers });
};

const put = (URL, data) => {
  const headers = { "x-access-token": Cookies.getJSON("token")?.token };
  return axios.put(URL, data, { withCredentials: true, headers: headers });
};

const getBlob = (URL, data) => {
  const headers = { "x-access-token": Cookies.getJSON("token")?.token };
  return fetch(URL, data, { headers: headers });
};

export { get, post, put, getBlob };
