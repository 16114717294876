import React from "react";

import { css, StyleSheet } from "aphrodite";

import AppNavBar from "components/layout/AppNavBar";
import backgroundImg from "asset/images/under_construction.gif";

function Home() {
  return (
    <div>
      <AppNavBar header="Home" />
      <p>Home page under construction</p>
      <div className={css(styles.background)}>
        <img src={backgroundImg} className="d-block mx-auto img-fluid" />
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  background: {
    backgroundColor: "#3ee8c4",
    height: "100vh"
  }
});

export default Home;
