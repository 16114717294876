import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Button, Col, Container, Form, Row } from "react-bootstrap";
import classNames from "classnames";
import { css, StyleSheet } from "aphrodite";
import DataTable from "react-data-table-component";
import { find } from "lodash-es";
import Swal from "sweetalert2";
import { useHistory, useLocation } from "react-router";

import AppNavBar from "components/layout/AppNavBar";
import { GET_ALL_ORDER_GUIDES } from "config/config";
import LoadingSpinner from "shared/LoadingSpinner";
import Logout from "shared/Logout";
import { post } from "networking/http";
import useVendorMap from "components/lib/hooks/useVendorMap";
import { SwalToast } from "components/lib/ui/toast";

function OrderGuideScraper() {
  const vendorMap = useVendorMap();
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [filteredItems, setFilteredItems] = useState();
  const [filteredVendor, setFilteredVendor] = useState("");
  const [filteredOperator, setFilteredOperator] = useState("");
  const [filteredStatusKey, setFilteredStatusKey] = useState("");

  const vendorName = useCallback((id, channel) => {
    const data = find(vendorMap, function(o) {
      return o.value == id && o.channel == channel;
    });
    return data?.label || "";
  }, []);

  useEffect(() => {
    if (filteredOperator) {
      const fill = tableData?.filter(e =>
        e.operator_id.includes(filteredOperator)
      );
      setFilteredItems(fill || []);
    } else if (filteredVendor) {
      const fill = tableData?.filter(e =>
        vendorName(e.vendor_id)
          .toLowerCase()
          .includes(filteredVendor.toLowerCase())
      );
      setFilteredItems(fill || []);
    } else if (filteredStatusKey) {
      const fill = tableData?.filter(e =>
        e.status_key.includes(filteredStatusKey)
      );
      setFilteredItems(fill || []);
    } else {
      setFilteredItems([]);
    }
  }, [filteredOperator, filteredVendor, filteredStatusKey]);

  useEffect(() => {
    setLoading(true);
    post(`${GET_ALL_ORDER_GUIDES}`)
      .then(res => {
        if (res) {
          setTableData(res.data);
          setLoading(false);
        }
      })
      .catch(err => {
        setLoading(false);
        if (err?.response?.data && err?.response.status === 401) {
          SwalToast(
            "success",
            `Token is not valid : ${err.response.data.message}`
          );
          Logout(history, location);
        } else if (err?.response?.data) {
          Swal.fire("Something Went Wrong", err.response.data.message, "error");
        } else {
          Swal.fire("Something Went Wrong", err.message, "error");
        }
      });
  }, []);

  const Details = ({ label, row }) => (
    <Button
      variant="link"
      onClick={() => {
        window.open(
          `/dashboard/orderguide-scraper/status_key/${row.status_key}`
        );
        // history.push({pathname: `/dashboard/orderguide-scraper/status_key/${row.status_key}`})
      }}
    >
      {label}
    </Button>
  );

  const columns = useMemo(
    () => [
      {
        name: "Vendor Id",
        selector: "vendor_id",
        sortable: true
      },
      {
        name: "Vendor Name",
        cell: row => vendorName(row.vendor_id, row.channel),
        sortable: true
      },
      {
        name: "Status Key",
        selector: "status_key",
        sortable: true,
        minWidth: "350px"
      },
      {
        name: "Status",
        selector: "status",
        sortable: true
      },
      {
        name: "Operator Id",
        selector: "operator_id",
        sortable: true
      },
      {
        name: "Time",
        selector: "time",
        sortable: true
      },
      {
        name: "Details",
        cell: row => (
          <Details label={"click for details"} row={row}>
            Action
          </Details>
        ),
        allowOverflow: true,
        button: true,
        minWidth: "200px"
      }
    ],
    []
  );

  const conditionalRowStyles = [
    {
      when: row => row.status === "successful",
      style: {
        backgroundColor: "#e2efda",
        color: "#686a6c",
        "&:hover": {
          cursor: "pointer"
        }
      }
    },
    {
      when: row => row.status === "unsuccessful",
      style: {
        backgroundColor: "#efdfdf",
        color: "#686a6c",
        "&:hover": {
          cursor: "pointer"
        }
      }
    },
    {
      when: row => row.status === "progress",
      style: {
        backgroundColor: "#eec780",
        color: "#686a6c",
        "&:hover": {
          cursor: "pointer"
        }
      }
    }
  ];

  return (
    <div>
      <AppNavBar header="Order Guide Scraper" />
      <If condition={loading}>
        <LoadingSpinner />
      </If>
      <Form>
        <Row className={css(styles.searchBar)}>
          <Col sm="1" className={css(styles.label)}>
            <Form.Label className="font-weight-bold text-muted">
              {"Vendor Name : "}
            </Form.Label>
          </Col>
          <Col sm="2" className={css(styles.input)}>
            <Form.Control
              size="sm"
              type="text"
              placeholder="Enter Vendor Name"
              value={filteredVendor}
              onChange={e => {
                setFilteredVendor(e.target.value);
              }}
            />
          </Col>
          <Col sm="1" className={css(styles.clear)}>
            <Button
              variant="info"
              size="sm"
              onClick={() => {
                setFilteredVendor("");
              }}
            >
              {"X"}
            </Button>
          </Col>
          <Col sm="1" className={css(styles.label)}>
            <Form.Label className="font-weight-bold text-muted">
              {"Operator Id :"}
            </Form.Label>
          </Col>
          <Col sm="2" className={css(styles.input)}>
            <Form.Control
              size="sm"
              type="text"
              placeholder="Enter Operator Id"
              value={filteredOperator}
              onChange={e => {
                setFilteredOperator(e.target.value);
              }}
            />
          </Col>
          <Col sm="1" className={css(styles.clear)}>
            <Button
              variant="info"
              size="sm"
              onClick={() => {
                setFilteredOperator("");
              }}
            >
              {"X"}
            </Button>
          </Col>
          <Col sm="1" className={css(styles.label)}>
            <Form.Label className="font-weight-bold text-muted">
              {"Status Key :"}
            </Form.Label>
          </Col>
          <Col sm="2" className={css(styles.input)}>
            <Form.Control
              size="sm"
              type="text"
              placeholder="Enter Status Key"
              value={filteredStatusKey}
              onChange={e => {
                setFilteredStatusKey(e.target.value);
              }}
            />
          </Col>
          <Col sm="1" className={css(styles.clear)}>
            <Button
              variant="info"
              size="sm"
              onClick={() => {
                setFilteredStatusKey("");
              }}
            >
              {"X"}
            </Button>
          </Col>
        </Row>
      </Form>
      <Container
        className={classNames(css(styles.container), "d-flex flex-column")}
      >
        <DataTable
          columns={columns}
          data={
            filteredVendor || filteredOperator || filteredStatusKey
              ? filteredItems
              : tableData
          }
          fixedHeader={true}
          paginationPerPage={100}
          paginationRowsPerPageOptions={[100, 150, 200]}
          conditionalRowStyles={conditionalRowStyles}
          pagination
        />
      </Container>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    maxWidth: "80%"
  },
  table: {
    marginTop: 25
  },
  searchBar: {
    justifyContent: "center",
    paddingTop: "3rem"
  },
  label: {
    paddingRight: "0rem",
    textAlign: "right"
  },
  input: {
    paddingLeft: "1rem",
    paddingRight: "0rem"
  },
  clear: {
    float: "right",
    paddingLeft: "0rem",
    height: "3rem",
    maxWidth: "1rem"
  }
});

export default OrderGuideScraper;
