import React, { lazy, Suspense } from "react";

import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  Route
} from "react-router-dom";
import AppContainer from "components/layout/AppContainer";
import { GoogleOAuthProvider } from '@react-oauth/google';
import LoadingSpinner from "shared/LoadingSpinner";

import { CLIENT_ID } from "config/config";

const Login = lazy(() => import("components/Login/Login"));
const Home = lazy(() => import("components/home/Home"));
const InvoiceScraper = lazy(() =>
  import("components/scraper/Invoice/InvoiceScraper")
);
const CatalogScraper = lazy(() =>
  import("components/scraper/Catalog/CatalogScraper")
);
const OrderGuidesScraper = lazy(() =>
  import("components/scraper/OrderGuide/OrderGuidesScraper")
);
const OrderPlacementsScraper = lazy(() =>
  import("components/scraper/OrderPlacements/OrderPlacementsScraper")
);
const Instances = lazy(() => import("components/Instances/InstanceStatusView"));
const Tasks = lazy(() => import("components/tasks/Tasks"));
const Catalogs = lazy(() => import("components/catalogs/Catalogs"));

function App() {
  return (
    <GoogleOAuthProvider clientId={CLIENT_ID}>
      <Router>
        <Suspense fallback={<LoadingSpinner />}>
          <Switch>
            <Route path="/" component={AppContainer} />
            <Route path="/login" component={Login} />
            <Route path="/dashboard/home" component={Home} />
            <Route path="/dashboard/invoice-scraper" component={InvoiceScraper} />
            <Route path="/dashboard/catalog-scraper" component={CatalogScraper} />
            <Route
              path="/dashboard/orderguide-scraper"
              component={OrderGuidesScraper}
            />
            <Route
              path="/dashboard/OrderPlacements-scraper"
              component={OrderPlacementsScraper}
            />
            <Route path="/dashboard/catalogs" component={Catalogs} />
            <Route path="/dashboard/instances" component={Instances} />
            <Route path="/dashboard/tasks" component={Tasks} />
            <Route render={() => <Redirect to={{ pathname: "/login" }} />} />
          </Switch>
        </Suspense>
      </Router>
    </GoogleOAuthProvider>
  );
}

export default App;
