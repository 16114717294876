import Cookies from "js-cookie";

import { closeSideBar } from "components/lib/methods/sidebarToggler";

function Logout(history, location) {
  Cookies.remove("token");
  closeSideBar();
  history.push("/login", { from: location.pathname });
}

export default Logout;
