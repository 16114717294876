import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { css, StyleSheet } from "aphrodite";
import { Badge, Col, Row } from "react-bootstrap";
import Select from "react-select";
import { find } from "lodash-es";
import dayjs from "dayjs";

import useVendorMap from "components/lib/hooks/useVendorMap";
import { useHistory, useParams } from "react-router";

function SearchBar({ itemCount, latestTimestamp, loading }) {
  const VendorDataMap = useVendorMap();
  const history = useHistory();
  const params = useParams();

  const selectedVendor = useMemo(() => {
    if (params.vendor_id) {
      return find(VendorDataMap, function(o) {
        return o.value == params.vendor_id;
      });
    }
  });

  return (
    <div className={css(styles.searchBar)}>
      <Row>
        <Col sm="2">{"Select Vendor"}</Col>
        <Col sm="3">
          <Select
            value={selectedVendor}
            options={VendorDataMap}
            onChange={vendor =>
              history.push(`/dashboard/catalog-scraper/vendor/${vendor.value}`)
            }
          ></Select>
        </Col>
        <Col sm="5">
          <Choose>
            <When condition={loading}>
              <Badge variant="primary" className={css(styles.badge)}>
                loading...
              </Badge>
            </When>
            <When condition={itemCount > 0}>
              <Badge variant="success" className={css(styles.badge)}>
                Total Catalog Items: {itemCount}
              </Badge>
              <Badge variant="primary" className={css(styles.badge)}>
                Scraped Date:{" "}
                {dayjs.unix(latestTimestamp).format("dddd, MMMM D")}
              </Badge>
            </When>
            <When condition={itemCount === 0}>
              <Badge variant="danger" className={css(styles.badge)}>
                No Data For This Vendor
              </Badge>
            </When>
          </Choose>
        </Col>
      </Row>
    </div>
  );
}

const styles = StyleSheet.create({
  searchBar: {
    marginTop: 10
  },
  badge: {
    fontSize: 20,
    marginRight: "10px",
    marginLeft: "10px"
  }
});

SearchBar.propTypes = {
  itemCount: PropTypes.number,
  latestTimestamp: PropTypes.number,
  loading: PropTypes.bool
};

export default SearchBar;
