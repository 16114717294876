import React, { useEffect, useMemo, useState } from "react";

import { Container } from "react-bootstrap";
import classNames from "classnames";
import { css, StyleSheet } from "aphrodite";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { SwalToast } from "components/lib/ui/toast";
import { useHistory, useLocation } from "react-router";

import AppNavBar from "components/layout/AppNavBar";
import { GET_CRON_JOBS } from "config/config";
import { get } from "networking/http";
import { lg } from "shared/screen";
import LoadingSpinner from "shared/LoadingSpinner";
import Logout from "shared/Logout";

function CronJobsView() {
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    setLoading(true);
    get(`${GET_CRON_JOBS}`)
      .then(res => {
        if (res) {
          setData(res.data);
          setLoading(false);
        }
      })
      .catch(err => {
        setLoading(false);
        if (err?.response?.data && err?.response.status === 401) {
          SwalToast(
            "success",
            `Token is not valid : ${err.response.data.message}`
          );
          Logout(history, location);
        } else if (err?.response?.data) {
          Swal.fire("Something Went Wrong", err.response.data.message, "error");
        } else {
          Swal.fire("Something Went Wrong", err.message, "error");
        }
      });
  }, []);

  const columns = useMemo(
    () => [
      {
        name: "Cron Job Name",
        selector: "name",
        sortable: true
      },
      {
        name: "Scheduled Time (H : m : day)",
        selector: "time",
        sortable: true,
        maxWidth: "150px",
        center: true,
        wrap: true
      }
    ],
    []
  );

  return (
    <>
      <AppNavBar header="Cron Jobs" />
      <If condition={loading}>
        <LoadingSpinner />
      </If>
      <Container
        className={classNames(css(styles.container), "d-flex flex-column")}
      >
        <DataTable columns={columns} data={data} fixedHeader={true} center />
      </Container>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    maxWidth: lg() ? "40%" : "100%"
  }
});

export default CronJobsView;
