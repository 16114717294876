import React, { useEffect, useMemo, useState } from "react";

import { Card, Container, Row, Tab, Table, Tabs } from "react-bootstrap";
import dayjs from "dayjs";
import classnames from "classnames";
import { css, StyleSheet } from "aphrodite";
import Swal from "sweetalert2";
import { useHistory, useLocation, useParams } from "react-router-dom";

import AppNavBar from "components/layout/AppNavBar";
import {
  CLOUD_WATCH_URL,
  GET_SINGLE_INVOICE_SCRAPE_DETAILS,
  INVOICE_ERROR_PNG,
  INVOICE_ERROR_FILE,
} from "config/config";
import InvoiceScraperScrapedDataView from "components/scraper/Invoice/InvoiceScraperScrapedDataView";
import InvoiceScraperScrapedItemsView from "components/scraper/Invoice/InvoiceScraperScrapedItemsView";
import LoadingSpinner from "shared/LoadingSpinner";
import Logout from "shared/Logout";
import { post } from "networking/http";
import { SwalToast } from "components/lib/ui/toast";

function InvoiceScraperStatusView() {
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState();
  const params = useParams();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    setLoading(true);
    post(`${GET_SINGLE_INVOICE_SCRAPE_DETAILS}${params?.status_key}`)
      .then((res) => {
        if (res) {
          setTableData(res.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err?.response?.data && err?.response.status === 401) {
          SwalToast(
            "success",
            `Token is not valid : ${err.response.data.message}`
          );
          Logout(history, location);
        } else if (err?.response?.data) {
          Swal.fire("Something Went Wrong", err.response.data.message, "error");
        } else {
          Swal.fire("Something Went Wrong", err.message, "error");
        }
      });
  }, []);

  const cloudWatchURL = useMemo(() => {
    const endTime = dayjs(tableData?.time).hour(24).format();
    const startTime = dayjs(tableData?.time).hour(-24).format();
    return (
      CLOUD_WATCH_URL +
      tableData?.status_key +
      "%22;start=" +
      startTime +
      ";end=" +
      endTime
    );
  }, [tableData]);

  return (
    <div>
      <AppNavBar header="Invoice Scraper" />
      <If condition={loading}>
        <LoadingSpinner />
      </If>
      <Tabs
        defaultActiveKey="main"
        id="uncontrolled-tab-example"
        className={css(styles.tab)}
      >
        <Tab eventKey="main" title="Invoice Scraping Details">
          <Choose>
            <When condition={tableData?.status === "unsuccessful"}>
              <Container
                className={classnames(
                  "d-flex flex-column",
                  css(styles.container)
                )}
              >
                <h1 className={css(styles.header)}>{"Scrape Details"}</h1>
                <Table
                  striped
                  bordered
                  hover
                  className={classnames("text-center", css(styles.table))}
                >
                  <thead>
                    <tr>
                      <th>{"Vendor Id"}</th>
                      <th>{"Operator Id"}</th>
                      <th>{"Status Key"}</th>
                      <th>{"Status"}</th>
                      <th>{"Cloud Watch Url"}</th>
                      <th>{"Error HTML"}</th>
                      <th>{"Error SS"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className={css(styles.tableRowDanger)}>
                      <td>{tableData.vendor_id}</td>
                      <td>{tableData.operator_id}</td>
                      <td>{tableData.status_key}</td>
                      <td>{tableData.status}</td>
                      <td>
                        <a href={cloudWatchURL} target="_blank">
                          {"cloud watch url"}
                        </a>
                      </td>
                      <td>
                        <a
                          href={
                            INVOICE_ERROR_FILE + tableData?.status_key + ".html"
                          }
                          target="_blank"
                        >
                          {"Error HTML url"}
                        </a>
                      </td>
                      <td>
                        <a
                          href={
                            INVOICE_ERROR_PNG + tableData?.status_key + ".png"
                          }
                          target="_blank"
                        >
                          {"Error SS url"}
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <Row>
                  <Card className={css(styles.cards)}>
                    <a
                      href={INVOICE_ERROR_PNG + tableData?.status_key + ".png"}
                      target="_blank"
                    >
                      <Card.Img
                        src={INVOICE_ERROR_PNG + tableData?.status_key + ".png"}
                        variant="top"
                      />
                    </a>
                    <Card.Body>
                      <Card.Title>{"Error Message"}</Card.Title>
                      <Card.Text>{tableData?.message}</Card.Text>
                    </Card.Body>
                    <Card.Footer>
                      <small className="text-muted">{tableData?.time}</small>
                    </Card.Footer>
                  </Card>
                </Row>
              </Container>
            </When>
            <When condition={tableData?.status === "successful"}>
              <Container
                className={classnames(
                  "d-flex flex-column",
                  css(styles.container)
                )}
              >
                <h1 className={css(styles.header)}>{"Scrape Details"}</h1>
                <Table
                  striped
                  bordered
                  hover
                  className={classnames("text-center", css(styles.table))}
                >
                  <thead>
                    <tr>
                      <th>{"Vendor Id"}</th>
                      <th>{"Operator Id"}</th>
                      <th>{"Status Key"}</th>
                      <th>{"Status"}</th>
                      <th>{"Started Time"}</th>
                      <th>{"Duration"}</th>
                      <th>{"Cloud Watch Url"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className={css(styles.tableRowSuccess)}>
                      <td>{tableData?.vendor_id}</td>
                      <td>{tableData.operator_id}</td>
                      <td>{tableData?.status_key}</td>
                      <td>{tableData?.status}</td>
                      <td>
                        {dayjs(tableData?.time.$date).format(
                          "YYYY-MM-DDTHH:mm:ss"
                        )}
                      </td>
                      <td>
                        {tableData?.stats?.duration
                          ? parseFloat(tableData?.stats?.duration).toFixed(2) +
                            " secs"
                          : "-- mins"}
                      </td>
                      <td>
                        <a href={cloudWatchURL} target="_blank">
                          {"cloud watch url"}
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Container>
            </When>
          </Choose>
        </Tab>
        <If condition={tableData?.scrape_details}>
          <Tab eventKey="request" title="Invoice Scraped Data">
            <InvoiceScraperScrapedDataView
              data={tableData?.scrape_details}
            ></InvoiceScraperScrapedDataView>
          </Tab>
        </If>
        <If condition={tableData?.scrape_details?.items}>
          <Tab eventKey="requestItems" title="Invoice Scraped Items">
            <InvoiceScraperScrapedItemsView
              items={tableData?.scrape_details?.items}
            ></InvoiceScraperScrapedItemsView>
          </Tab>
        </If>
      </Tabs>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    maxWidth: "90%",
    justifyContent: "center",
    marginLeft: "0rem",
  },
  table: {
    marginTop: 15,
  },
  tableRowDanger: {
    background: "#efdfdf",
  },
  tableRowSuccess: {
    background: "#e2efda",
  },
  tableRowProgress: {
    background: "#eec780",
  },
  header: {
    fontFamily: "Orbitron",
    fontSize: 25,
    letterSpacing: 1,
    justifyContent: "center",
    marginLeft: "2rem",
    marginTop: "2rem",
  },
  cards: {
    width: "20rem",
    margin: "1rem",
  },
});
export default InvoiceScraperStatusView;
