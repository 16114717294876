import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Pagination from "react-js-pagination";
import { Row, Container } from "react-bootstrap";
import { css, StyleSheet } from "aphrodite";

function PaginationBar({
  activePage,
  itemsCount,
  offsetFunction,
  currentActivePage
}) {
  const rowPerPage = 1000;
  const [rangeFloorValue, setRangeFloorValue] = useState(1);

  useEffect(() => {
    setRangeFloorValue(activePage * rowPerPage - (rowPerPage - 1));
  }, [activePage]);

  return (
    <Container fluid>
      <Row className={css(styles.paginationBar)}>
        <h6 className={css(styles.paginationText)}>
          {"Rows Per Page : "}
          {rowPerPage}
        </h6>
        <h6 className={css(styles.paginationRangeText)}>
          {rangeFloorValue} - {activePage * rowPerPage}
        </h6>
        <Pagination
          activePage={activePage}
          itemsCountPerPage={rowPerPage}
          totalItemsCount={itemsCount}
          pageRangeDisplayed={5}
          itemClass="page-item"
          linkClass="page-link"
          onChange={e => {
            currentActivePage(e);
            setRangeFloorValue(e * rowPerPage - (rowPerPage - 1));
            offsetFunction((e - 1) * rowPerPage); // page numbering is starting from 1 but offset should start from 0 so (n-1)
          }}
        />
      </Row>
    </Container>
  );
}

const styles = StyleSheet.create({
  paginationBar: {
    marginTop: "2rem",
    align: "right",
    justifyContent: "right",
    float: "right"
  },
  paginationText: {
    marginTop: "11px",
    fontSize: "13px",
    fontFamily: "sans-serif"
  },
  paginationRangeText: {
    marginLeft: "1rem",
    marginRight: "1rem",
    marginTop: "11px",
    fontSize: "13px",
    fontFamily: "sans-serif"
  }
});

PaginationBar.propTypes = {
  activePage: PropTypes.number,
  itemsCount: PropTypes.number,
  offsetFunction: PropTypes.func,
  currentActivePage: PropTypes.func
};

export default PaginationBar;
