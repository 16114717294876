import React, { useEffect, useState } from "react";

import { useHistory, useLocation, Route, Switch } from "react-router-dom";
import Cookies from "js-cookie";

import Home from "components/home/Home";
import Login from "components/Login/Login";
import OrderGuideScraper from "components/scraper/OrderGuide/OrderGuideScraper";
import OrderGuideScraperStatusView from "components/scraper/OrderGuide/OrderGuideScraperStatusView";
import OrderGuideScrapeItemsView from "components/scraper/OrderGuide/OrderGuideScrapeItemsView";
import InvoiceScraper from "components/scraper/Invoice/InvoiceScraper";
import InvoiceScraperStatusView from "components/scraper/Invoice/InvoiceScraperStatusView";
import CatalogRuns from "components/catalogsRun/CatalogRuns";
import CatalogScraper from "components/scraper/Catalog/CatalogScraper";
import CatalogScraperVendorView from "components/scraper/Catalog/CatalogScraperVendorView";
import CatalogScraperVendorItemView from "components/scraper/Catalog/CatalogScraperVendorItemView";
import CronJobsView from "components/cronJobs/CronJobsView";
import OrderGuidesScraper from "components/scraper/OrderGuide/OrderGuidesScraper";
import OrderPlacementsScraper from "components/scraper/OrderPlacements/OrderPlacementsScraper";
import OrderPlacementStatusView from "components/scraper/OrderPlacements/OrderPlacementStatusView";
import Instances from "components/Instances/InstanceStatusView";
import Catalogs from "components/catalogs/Catalogs";
import Tasks from "components/tasks/Tasks";
import IntegrationTest from "components/Integrations/ItegrationTests";
import AppNavSideBar from "components/layout/AppNavSideBar";
import { AuthContext } from "components/lib/auth";
import { closeSideBar } from "components/lib/methods/sidebarToggler";

function AppContainer() {
  const history = useHistory();
  const location = useLocation();
  const [authToken, setAuthToken] = useState(Cookies.getJSON("token") || false);

  useEffect(() => {
    closeSideBar();
    if (authToken) {
      if (location.pathname === "/login" && location?.state?.from) {
        history.push(location.state.from);
      } else if (
        location.pathname === "/login" ||
        location.pathname === undefined ||
        location.pathname === "/"
      ) {
        history.push("/dashboard/home");
      } else if (location.pathname) {
        history.push(location.pathname);
      } else {
        history.push("/dashboard/home");
      }
    } else {
      history.push("/login");
    }
  }, [authToken]);

  return (
    <AuthContext.Provider value={[authToken, setAuthToken]}>
      <div className="page-wrapper chiller-theme toggled" id="changer">
        <AppNavSideBar />
        <main className="page-content">
          <Switch>
            <Route path="/login" render={props => <Login {...props} />} />
            <Route
              exact
              path="/dashboard/orderguide-scraper"
              render={props => <OrderGuideScraper />}
            ></Route>
            <Route
              exact
              path="/dashboard/orderguide-scraper/details/status_key/:status_key"
              render={props => <OrderGuideScraperStatusView {...props} />}
            />
            <Route
              exact
              path="/dashboard/orderguide-scraper/object_id/:object_id"
              render={props => <OrderGuideScrapeItemsView {...props} />}
            />
            <Route
              exact
              path="/dashboard/invoice-scraper/:page"
              render={props => <InvoiceScraper {...props} />}
            />
            <Route
              exact
              path="/dashboard/invoice-scraper/vendor/:vendor_id/:page"
              render={props => <InvoiceScraper {...props} />}
            />
            <Route
              exact
              path="/dashboard/invoice-scraper/operator/:operator_id/:page"
              render={props => <InvoiceScraper {...props} />}
            />
            <Route
              exact
              path="/dashboard/invoice-scraper/statuskey/:status_key/:page"
              render={props => <InvoiceScraper {...props} />}
            />
            <Route
              path="/dashboard/invoice-scraper/details/status_key/:status_key"
              render={props => <InvoiceScraperStatusView {...props} />}
            />
            <Route
              exact
              path="/dashboard/catalog-scraper"
              render={props => <CatalogScraper {...props} />}
            />
            <Route
              exact
              path="/dashboard/catalog-scraper/vendor/:vendor_id"
              render={props => <CatalogScraperVendorView {...props} />}
            />
            <Route
              exact
              path="/dashboard/catalog-scraper/vendor/:vendor_id/:page"
              render={props => <CatalogScraperVendorView {...props} />}
            />
            <Route
              exact
              path="/dashboard/catalog-scraper/vendor/:vendor_id/itemcode/:item_code/:page"
              render={props => <CatalogScraperVendorView {...props} />}
            />
            <Route
              exact
              path="/dashboard/catalog-scraper/vendor/:vendor_id/itemname/:product_name/:page"
              render={props => <CatalogScraperVendorView {...props} />}
            />
            <Route
              exact
              path="/dashboard/catalog-scraper/vendor/:vendor_id/item/:item_code"
              render={props => <CatalogScraperVendorItemView {...props} />}
            />
            <Route
              exact
              path="/dashboard/orderguide-scraper"
              render={props => <OrderGuidesScraper {...props} />}
            />
            <Route
              exact
              path="/dashboard/orderguide-scraper/:page"
              render={props => <OrderGuidesScraper {...props} />}
            />
            <Route
              exact
              path="/dashboard/orderguide-scraper/vendor/:vendor_id/:page"
              render={props => <OrderGuidesScraper {...props} />}
            />
            <Route
              exact
              path="/dashboard/orderguide-scraper/operator/:operator_id/:page"
              render={props => <OrderGuidesScraper {...props} />}
            />
            <Route
              path="/dashboard/orderguide-scraper/statuskey/:status_key/:page"
              render={props => <OrderGuidesScraper {...props} />}
            />
            <Route
              exact
              path="/dashboard/OrderPlacements-scraper"
              render={props => <OrderPlacementsScraper {...props} />}
            />
            <Route
              exact
              path="/dashboard/OrderPlacements-scraper/:page"
              render={props => <OrderPlacementsScraper {...props} />}
            />
            <Route
              exact
              path="/dashboard/OrderPlacements-scraper/vendor/:vendor_id/:page"
              render={props => <OrderPlacementsScraper {...props} />}
            />
            <Route
              exact
              path="/dashboard/OrderPlacements-scraper/operator/:operator_id/:page"
              render={props => <OrderPlacementsScraper {...props} />}
            />
            <Route
              path="/dashboard/OrderPlacements-scraper/statuskey/:status_key/:page"
              render={props => <OrderPlacementsScraper {...props} />}
            />
            <Route
              path="/dashboard/OrderPlacements-scraper/order/:cd_order_id/:page"
              render={props => <OrderPlacementsScraper {...props} />}
            />
            <Route
              path="/dashboard/OrderPlacements-scraper/details/statuskey/:status_key"
              render={props => <OrderPlacementStatusView {...props} />}
            />
            <Route
              path="/dashboard/home"
              render={props => <Home {...props} />}
            />
            <Route
              path="/dashboard/catalogs"
              render={props => <Catalogs {...props} />}
            />
            <Route
              path="/dashboard/integrations"
              render={props => <IntegrationTest {...props} />}
            />
            <Route
              path="/dashboard/instances"
              render={props => <Instances {...props} />}
            />
            <Route
              path="/dashboard/cron_jobs"
              render={props => <CronJobsView {...props} />}
            />
            <Route
              path="/dashboard/tasks"
              render={props => <Tasks {...props} />}
            />
            <Route
              path="/dashboard/catalogs_runs"
              render={props => <CatalogRuns {...props} />}
            />
            <Route path="*" render={props => <Login {...props} />} />
          </Switch>
        </main>
      </div>
    </AuthContext.Provider>
  );
}

export default AppContainer;
