import React, { useEffect, useState } from "react";

import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Table
} from "react-bootstrap";
import classnames from "classnames";
import { css, StyleSheet } from "aphrodite";
import dayjs from "dayjs";
import { isEqual } from "lodash-es";
import Swal from "sweetalert2";
import Select from "react-select";
import { useHistory, useLocation, useParams } from "react-router";

import AppNavBar from "components/layout/AppNavBar";
import { get, post } from "networking/http";
import {
  GET_CATELOG_DETAILS_ITEM,
  POST_PUSH_CATELOG_ITEM,
  POST_CATALOG_ITEM,
  ITEM_STATUS
} from "config/config";
import LoadingSpinner from "shared/LoadingSpinner";
import Logout from "shared/Logout";
import { SwalToast } from "components/lib/ui/toast";

function CatalogScraperVendorItemView() {
  const params = useParams();
  const history = useHistory();
  const location = useLocation();

  const [tableHeaders, setTableHeaders] = useState([]);
  const [originalItemData, setOriginalItemData] = useState({});
  const [itemData, setItemData] = useState({});
  const [singleItem, setSingleItem] = useState({});
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    setLoading(true);
    get(
      `${GET_CATELOG_DETAILS_ITEM}${params.vendor_id}&item=${params.item_code}`
    )
      .then(res => {
        if (res) {
          setTableData(res.data.catalog_item[0]);
          setItemData(res.data.catalog_item[0].item);
          setLoading(false);
          setRefetch(false);
        }
      })
      .catch(err => {
        setLoading(false);
        if (err?.response?.data && err?.response.status === 401) {
          SwalToast(
            "success",
            `Token is not valid : ${err.response.data.message}`
          );
          Logout(history, location);
        } else if (err?.response?.data) {
          Swal.fire("Something Went Wrong", err.response.data.message, "error");
        } else {
          Swal.fire("Something Went Wrong", err.message, "error");
        }
      });
  }, [params.vendor_id, params.item_code, refetch]);

  const flattenObject = obj => {
    const flattened = {};
    Object.keys(obj).forEach(key => {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        Object.assign(flattened, flattenObject(obj[key]));
      } else {
        flattened[key] = obj[key];
      }
    });
    return flattened;
  };

  useEffect(() => {
    if (itemData) {
      const flattered_object = flattenObject(itemData);
      setTableHeaders(Object.keys(flattered_object || []));
      setSingleItem(flattered_object);
      setOriginalItemData(flattenObject(tableData?.item || {}));
    }
  }, [itemData]);

  function pushUpdatedItem() {
    setLoading(true);
    post(POST_CATALOG_ITEM, {
      vendor_id: params.vendor_id,
      item_code: singleItem.item_code,
      item: singleItem,
      channel: tableData?.channel,
      uom: singleItem?.uom
    })
      .then(res => {
        if (res) {
          setIsUpdated(false);
          SwalToast(
            "success",
            `Succesfully Updated The Item Code ${itemData.item_code}`
          );
          setLoading(false);
          setRefetch(true);
        }
      })
      .catch(err => {
        setLoading(false);
        if (err?.response?.data) {
          Swal.fire(
            "Something Went Wrong",
            JSON.stringify(err.response.data.error),
            "error"
          );
        } else {
          Swal.fire("Login Failed!", "Please Try Again", "error");
        }
      });
  }

  function pushItem() {
    setLoading(true);
    post(POST_PUSH_CATELOG_ITEM, {
      vendor_id: params.vendor_id,
      item_code: itemData.item_code,
      channel: tableData?.channel,
      uom: itemData?.uom
    })
      .then(res => {
        if (res) {
          SwalToast(
            "success",
            `Succesfully Added The Item Code ${itemData.item_code}`
          );
          setLoading(false);
        }
      })
      .catch(err => {
        setLoading(false);
        if (err?.response?.data) {
          Swal.fire(
            "Something Went Wrong",
            JSON.stringify(err.response.data.error),
            "error"
          );
        } else {
          Swal.fire("Login Failed!", "Please Try Again", "error");
        }
      });
  }

  return (
    <div>
      <AppNavBar header="Catalog Scraper Single Item View" />
      <Container className="d-sm-flex flex-column">
        <If condition={loading}>
          <LoadingSpinner />
        </If>
        <Row className={css(styles.productImageRow)}>
          <Col sm="2" className={css(styles.productImage)}>
            <Card>
              <Card.Img
                variant="top"
                src={"http://" + itemData?.original_image_url}
              />
            </Card>
          </Col>
          <Col sm="8">
            <span className={css(styles.productName)}>
              {itemData?.product_name} - {itemData?.item_code}
            </span>
          </Col>
        </Row>
        <Row className={css(styles.pushButton)}>
          <Col sm="2" className={css(styles.pushButtonColumn)}>
            <Button variant="info" size="sm" onClick={pushItem} block>
              {"Push To C+D"}
            </Button>
          </Col>
          <If condition={tableData?.is_manually_edited}>
            <Col sm="2">
              <Badge variant="success" className={css(styles.manuallyEdited)}>
                {"Manually Edited"}
              </Badge>
            </Col>
          </If>
        </Row>
        <Row>
          <Table
            striped
            bordered
            hover
            className={classnames("text-center", css(styles.table))}
          >
            <tbody>
              <For each="header" index="index" of={tableHeaders}>
                <tr key={index}>
                  <td className={css(styles.head)}>{header}</td>
                  <td>
                    <Choose>
                      <When
                        condition={["uom", "item_code"].includes(
                          { header }.header
                        )}
                      >
                        <Form.Control
                          disabled={true}
                          type="text"
                          placeholder={singleItem[header]}
                          value={singleItem[header] || ""}
                        />
                      </When>
                      <When condition={header == "item_status"}>
                        <Select
                          value={{
                            value: singleItem[header],
                            label: singleItem[header]
                          }}
                          options={ITEM_STATUS}
                          onChange={o => {
                            setIsUpdated(true);
                            setSingleItem({
                              ...singleItem,
                              item_status: o.label
                            });
                          }}
                        ></Select>
                      </When>
                      <Otherwise>
                        <Form.Control
                          type="text"
                          placeholder={singleItem[header]}
                          value={singleItem[header] || ""}
                          onChange={e => {
                            setIsUpdated(true);
                            const key = { header }.header;
                            const temp = singleItem;
                            temp[key] = e.target.value;
                            setSingleItem({ ...temp });
                          }}
                        />
                      </Otherwise>
                    </Choose>
                  </td>
                </tr>
              </For>
              <tr>
                <td className={css(styles.head)}>{"Synced to C+D time"}</td>
                <td>
                  {tableData.pushed_to_cd_ts
                    ? dayjs
                        .unix(tableData.pushed_to_cd_ts)
                        .format("YYYY-MM-DDTHH:mm:ss")
                    : "-"}
                </td>
              </tr>
            </tbody>
          </Table>
        </Row>
        <Row>
          <Col sm={1} className={css(styles.buttonColumns)}>
            <Button
              variant="success"
              disabled={isEqual(singleItem, originalItemData)}
              onClick={pushUpdatedItem}
            >
              {"Save"}
            </Button>
          </Col>
          <Col sm={1} className={css(styles.buttonColumns)}>
            <Button
              variant="danger"
              disabled={isEqual(singleItem, originalItemData)}
              onClick={() => {
                setIsUpdated(false);
                const flattered_object = flattenObject(itemData);
                setTableHeaders(Object.keys(flattered_object || []));
                setSingleItem(flattered_object);
              }}
            >
              {"Cancel"}
            </Button>
          </Col>
          <If condition={isUpdated && !isEqual(singleItem, originalItemData)}>
            <Col>
              <span className={css(styles.changesText)}>
                {"Unsaved Changes"}
              </span>
            </Col>
          </If>
        </Row>
      </Container>
    </div>
  );
}

const styles = StyleSheet.create({
  table: {
    marginTop: "2rem"
  },
  head: {
    backgroundColor: "#B4D5DE"
  },
  card: {
    height: "2rem"
  },
  productName: {
    fontSize: "2rem",
    fontFamily: "Orbitron",
    position: "absolute"
  },
  productImage: {
    paddingLeft: "0rem"
  },
  productImageRow: {
    marginTop: "2rem"
  },
  pushButton: {
    marginTop: "1.5rem"
  },
  pushButtonColumn: {
    paddingLeft: "0rem"
  },
  buttonColumns: {
    paddingRight: "5px",
    paddingLeft: "5px"
  },
  changesText: {
    color: "red"
  },
  manuallyEdited: {
    fontSize: 20,
    marginRight: "10px",
    marginLeft: "10px",
    background: "#48CFAD"
  },
  updatedTimestamp: {
    display: "block"
  }
});

export default CatalogScraperVendorItemView;
