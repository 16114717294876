import { lg } from "shared/screen";

const handleSideBarToggle = () => {
  var element = document.getElementById("changer");
  if (!lg()) {
    if (element.classList.value.includes("toggled")) {
      document.getElementsByTagName("body")[0].style = "margin-left: 0px";
    } else {
      document.getElementsByTagName("body")[0].style = "margin-left: 250px";
    }
  }
  element.classList.toggle("toggled");
};

const closeSideBar = () => {
  var element = document.getElementById("changer");
  document.getElementsByTagName("body")[0].style = "margin-left: 0px";

  if (element && Object.values(element.classList).includes("toggled")) {
    element.classList.remove("toggled");
  }
};

const handlecommentsToggle = () => {
  var element = document.getElementById("changer");
  element.classList.toggle("comments-toggled");
};

const closeComments = () => {
  var element = document.getElementById("changer");

  if (
    element &&
    Object.values(element.classList).includes("comments-toggled")
  ) {
    element.classList.remove("comments-toggled");
  }
};

export {
  handleSideBarToggle,
  closeSideBar,
  handlecommentsToggle,
  closeComments
};
