import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { useHistory } from "react-router";
import { Button } from "react-bootstrap";
import { find } from "lodash-es";
import dayjs from "dayjs";

import useVendorMap from "components/lib/hooks/useVendorMap";

function CatalogScraperRow({ index, VendorDetails }) {
  const history = useHistory();
  const vendorMap = useVendorMap();

  if (!VendorDetails) {
    return null;
  }

  const vendorName = useMemo(() => {
    const data = find(vendorMap, function(o) {
      return (
        o.value == VendorDetails.vendor_id && o.channel == VendorDetails.channel
      );
    });
    return data?.label || "";
  }, [VendorDetails]);

  return (
    <tr key={VendorDetails.vendor_id}>
      <td>{index + 1}</td>
      <td
        onClick={() =>
          history.push({
            pathname: `/dashboard/catalog-scraper/vendor/${VendorDetails.vendor_id}/1`
          })
        }
      >
        <Button variant="link">{vendorName}</Button>
      </td>
      <td>{VendorDetails.item_count}</td>
      <td>{VendorDetails.phased_out_count}</td>
      <td>
        {VendorDetails.latest_updated_timestamp
          ? dayjs
              .unix(VendorDetails.latest_updated_timestamp)
              .format("YYYY/MM/D HH:mm:ss")
          : "-"}
      </td>
      <td>
        {VendorDetails.last_synced_time
          ? dayjs
              .unix(VendorDetails.last_synced_time)
              .format("YYYY/MM/D HH:mm:ss")
          : "-"}
      </td>
      <td
        onClick={() =>
          history.push({
            pathname: `/dashboard/catalog-scraper/vendor/${VendorDetails.vendor_id}/1`
          })
        }
      >
        <Button variant="link">Click For Details</Button>
      </td>
    </tr>
  );
}

CatalogScraperRow.propTypes = {
  index: PropTypes.number,
  VendorDetails: PropTypes.object
};

export default CatalogScraperRow;
