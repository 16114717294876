import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Button, Col, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const PARAMS = {
  usfoods_catalog: {
    category: "",
    sub_category: "",
    category_position: "",
    sub_category_position: "",
    paginate_till_page: ""
  },
  webstaurant_catalog: {
    category_url: ""
  }
};

function AdditionalParametersSection({
  vendorName,
  additionalParameters,
  setAdditionalParameters
}) {
  const [parameters, setParameters] = useState(
    PARAMS[vendorName] ? Object.keys(PARAMS[vendorName]) : []
  );
  const [newParameter, setNewParameter] = useState("");

  useEffect(() => {
    if (vendorName) {
      setParameters(PARAMS[vendorName] ? Object.keys(PARAMS[vendorName]) : []);
      setAdditionalParameters(PARAMS[vendorName] || {});
    }
  }, [vendorName]);

  return (
    <>
      <Row className="mt-3 mb-2">
        <Col xs={12} md={4}>
          <Form.Label>{"Additional Parameters"}</Form.Label>
        </Col>
        <Col xs={10} md={4} className="pl-2">
          <Form.Control
            type="text"
            placeholder={"Add a Parameter"}
            value={newParameter}
            onChange={e => {
              setNewParameter(e.target.value);
            }}
          />
        </Col>
        <Col xs={2} md={4}>
          <Button
            onClick={() => {
              setParameters([...parameters, newParameter]);
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </Col>
      </Row>
      <For each="param" of={parameters}>
        <Col
          xs={12}
          md={{ span: 4, offset: 4 }}
          className="pl-0 mt-1"
          key={param}
        >
          <Form.Control
            type="text"
            placeholder={param}
            value={additionalParameters[param] || ""}
            onChange={e => {
              let temp = { ...additionalParameters };
              temp[param] = e.target.value;
              setAdditionalParameters({ ...temp });
            }}
          />
        </Col>
      </For>
    </>
  );
}

AdditionalParametersSection.propTypes = {
  vendorName: PropTypes.string,
  additionalParameters: PropTypes.object,
  setAdditionalParameters: PropTypes.func
};

export default AdditionalParametersSection;
