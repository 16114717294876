import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Button, Col, Container, Form, Row } from "react-bootstrap";
import classNames from "classnames";
import { css, StyleSheet } from "aphrodite";
import DataTable from "react-data-table-component";
import { find } from "lodash-es";
import Select from "react-select";
import Swal from "sweetalert2";
import { useHistory, useLocation, useParams } from "react-router-dom";

import AppNavBar from "components/layout/AppNavBar";
import { get } from "networking/http";
import { GET_ALL_INVOICES } from "config/config";
import LoadingSpinner from "shared/LoadingSpinner";
import Logout from "shared/Logout";
import { lg } from "shared/screen";
import PaginationBar from "shared/PaginationBar";
import { post } from "networking/http";
import { POST_SCRAPER_STATUS_CHANGE } from "config/config";
import { SwalToast } from "components/lib/ui/toast";
import useVendorMap from "components/lib/hooks/useVendorMap";

const paramMapping = {
  vendor_id: "vendor",
  operator_id: "operator",
  status_key: "statuskey"
};

function InvoiceScraper() {
  const [loading, setLoading] = useState(false);
  const [refetch, setRefetch] = useState(true);
  const [tableData, setTableData] = useState([]);

  const vendorMap = useVendorMap();
  const history = useHistory();
  const location = useLocation();
  const params = useParams();

  const [page, setPage] = useState(parseInt((params.page - 1) * 1000));
  const [activePage, setActivePage] = useState(parseInt(params.page));

  const [selectedOption, setSelectedOption] = useState(() => {
    const temp_params = { ...params };
    delete temp_params.page;
    return Object.values(temp_params)[0]
      ? {
          value: Object.keys(temp_params)[0],
          label: Object.keys(temp_params)[0]
        }
      : [];
  });

  const [searchValue, setSearchValue] = useState(() => {
    const temp_params = { ...params };
    delete temp_params.page;
    return Object.values(temp_params)[0] ? Object.values(temp_params)[0] : "";
  });

  const vendorName = useCallback((id, channel) => {
    const data = find(vendorMap, function(o) {
      return o.value == id && o.channel == channel;
    });
    return data?.label || "";
  }, []);

  useEffect(() => {
    if (refetch) {
      const temp_params = { ...params };
      delete temp_params.page;
      const query = searchValue
        ? `${GET_ALL_INVOICES}?${Object.keys(temp_params)[0]}=${
            Object.values(temp_params)[0]
          }&offset=${page}`
        : `${GET_ALL_INVOICES}?offset=${page}`;
      setLoading(true);
      get(query)
        .then(res => {
          if (res) {
            setTableData(res.data);
            setLoading(false);
            setRefetch(false);
          }
        })
        .catch(err => {
          setRefetch(false);
          if (err?.response?.data) {
            Swal.fire(
              "Something Went Wrong",
              err.response.data.message,
              "error"
            );
            Logout(history, location);
          } else {
            Swal.fire("Something Went Wrong", err.message, "error");
          }
        });
    }
  }, [
    params.vendor_id,
    params.page,
    params.operator_id,
    params.status_key,
    refetch
  ]);

  const Details = ({ label, row }) => (
    <Button
      variant="link"
      onClick={() => {
        window.open(
          `/dashboard/invoice-scraper/details/status_key/${row.status_key}`
        );
      }}
    >
      {label}
    </Button>
  );

  function StatusButton({ label, row }) {
    if (row.status === "progress") {
      return (
        <Button
          variant="danger"
          onClick={() => {
            if (
              window.confirm(
                "Are you sure you want to mark this scraping job unsuccessful?"
              )
            ) {
              post(POST_SCRAPER_STATUS_CHANGE, {
                status_key: row.status_key,
                status_to_update: "unsuccessful"
              })
                .then(res => {
                  if (res) {
                    SwalToast("success", `Succesfully Updated`);
                    setRefetch(true);
                  }
                })
                .catch(err => {
                  if (err?.response?.data) {
                    Swal.fire(
                      "Something Went Wrong",
                      JSON.stringify(err.response.data.error),
                      "error"
                    );
                  } else {
                    Swal.fire(
                      "Something Went Wrong",
                      "Please Try Again",
                      "error"
                    );
                  }
                });
            }
          }}
        >
          {label}
        </Button>
      );
    }
    return null;
  }

  const columns = useMemo(
    () => [
      {
        name: "Vendor Id",
        selector: "vendor_id",
        sortable: true,
        minWidth: "120px",
        maxWidth: "120px"
      },
      {
        name: "Vendor Name",
        cell: row => vendorName(row.vendor_id, row.channel),
        sortable: true
      },
      {
        name: "Status Key",
        selector: "status_key",
        sortable: true,
        minWidth: "350px"
      },
      {
        name: "Status",
        selector: "status",
        sortable: true,
        maxWidth: "120px"
      },
      {
        name: "Operator Id",
        selector: "operator_id",
        sortable: true,
        minWidth: "120px",
        maxWidth: "120px"
      },
      {
        name: "Time",
        selector: "time",
        sortable: true,
        minWidth: "240px",
        maxWidth: "240px"
      },
      {
        name: "Details",
        cell: row => (
          <Details label={"click for details"} row={row}>
            Action
          </Details>
        ),
        allowOverflow: true,
        button: true,
        minWidth: "150px",
        maxWidth: "150px"
      },
      {
        name: "Mark as Failed",
        cell: row => (
          <StatusButton label={"Mark As Failed"} row={row}>
            Action
          </StatusButton>
        ),
        allowOverflow: true,
        button: true,
        minWidth: "140px",
        maxWidth: "140px"
      }
    ],
    []
  );

  const conditionalRowStyles = [
    {
      when: row => row.status === "successful",
      style: {
        backgroundColor: "#e2efda",
        color: "#686a6c",
        "&:hover": {
          cursor: "pointer"
        }
      }
    },
    {
      when: row => row.status === "unsuccessful",
      style: {
        backgroundColor: "#efdfdf",
        color: "#686a6c",
        "&:hover": {
          cursor: "pointer"
        }
      }
    },
    {
      when: row => row.status === "progress",
      style: {
        backgroundColor: "#eec780",
        color: "#686a6c",
        "&:hover": {
          cursor: "pointer"
        }
      }
    }
  ];

  function changeActivePage(page) {
    setActivePage(page);
    const temp_params = { ...params };
    delete temp_params.page;
    if (Object.keys(temp_params).length > 0) {
      history.push({
        pathname: `/dashboard/invoice-scraper/${
          paramMapping[Object.keys(temp_params)[0]]
        }/${Object.values(temp_params)[0]}/${page}`
      });
    } else {
      history.push({ pathname: `/dashboard/invoice-scraper/${page}` });
    }
    setRefetch(true);
  }

  return (
    <div>
      <AppNavBar header="Invoice Scraper" />
      <If condition={loading}>
        <LoadingSpinner />
      </If>
      <Form>
        <Row className={css(styles.searchBar)}>
          <Col sm="1" className={css(styles.label)}>
            <Select
              value={selectedOption}
              defaultOptions
              options={[
                { label: "Vendor ID", value: "vendor_id" },
                { label: "Status Key", value: "status_key" },
                { label: "Operator ID", value: "operator_id" }
              ]}
              onChange={e => {
                setSelectedOption(e);
              }}
            ></Select>
          </Col>
          <Col sm="2" className={css(styles.input)}>
            <Form.Control
              size="md"
              type="text"
              placeholder={searchValue || selectedOption?.label}
              onChange={e => {
                setSearchValue(e.target.value);
              }}
            />
          </Col>
          <Col sm="2">
            <Button
              onClick={() => {
                setPage(0);
                history.push({
                  pathname: `/dashboard/invoice-scraper/${
                    paramMapping[selectedOption.value]
                  }/${searchValue}/${1}`
                });
                setRefetch(true);
                setActivePage(1);
              }}
            >
              {"Search"}
            </Button>
          </Col>
        </Row>
      </Form>
      <PaginationBar
        activePage={activePage}
        itemsCount={5000}
        offsetFunction={setPage}
        currentActivePage={changeActivePage}
      ></PaginationBar>
      <Container
        className={classNames(css(styles.container), "d-flex flex-column")}
      >
        <DataTable
          columns={columns}
          data={tableData}
          fixedHeader={true}
          conditionalRowStyles={conditionalRowStyles}
        />
      </Container>
      <PaginationBar
        activePage={activePage}
        itemsCount={5000}
        offsetFunction={setPage}
        currentActivePage={changeActivePage}
      ></PaginationBar>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    maxWidth: lg() ? "100%" : "100%"
  },
  table: {
    marginTop: 25
  },
  searchBar: {
    justifyContent: "center",
    paddingTop: "3rem"
  },
  label: {
    paddingRight: "0rem",
    textAlign: "right"
  },
  input: {
    paddingLeft: "1rem",
    paddingRight: "0rem"
  },
  clear: {
    float: "right",
    paddingLeft: "0rem",
    height: "3rem",
    maxWidth: "1rem"
  }
});

export default InvoiceScraper;
