import React, { useContext } from "react";

import { Container, Card, Row } from "react-bootstrap";
import Cookies from "js-cookie";
import classNames from "classnames";
import { css, StyleSheet } from "aphrodite";
import { GoogleLogin } from '@react-oauth/google';
import Swal from "sweetalert2";

import LoginView from "asset/images/login.jpeg";
import background from "asset/images/background.jpeg";
import { AuthContext } from "components/lib/auth";
import { CLIENT_ID, GET_LOGIN } from "config/config";
import { get } from "networking/http";

const clientId = CLIENT_ID;

function Login() {
  const [, setAuthToken] = useContext(AuthContext);

  function responseGoogle(response) {
    get(`${GET_LOGIN}`, { "x-access-token": response.credential })
      .then(res => {
        if (res) {
          const tokenData = {
            token: response.credential,
            accessLevel: res?.data.access_level
          };
          Cookies.set("token", tokenData);
          setAuthToken(tokenData);
        }
      })
      .catch(err => {
        if (err?.response?.data) {
          Swal.fire("Login Failed!", err.response.data.message, "error");
        } else {
          Swal.fire("Something Went Wrong", err.message, "error");
        }
      });
  }

  return (
    <div className={css(styles.background)}>
      <Container
        className={classNames(css(styles.login), "justify-content-center")}
      >
        <Row>
          <Card className={classNames(css(styles.loginCard), "text-center")}>
            <Card.Img
              variant="top"
              className={css(styles.imge)}
              src={LoginView}
              fill="blue"
            />
            <Card.Body>
              <Card.Title className="justify-content-center">
                Scraper Dashboard
              </Card.Title>
            <Row className="justify-content-center my-4">
              <GoogleLogin
                onSuccess={responseGoogle}
                onError={() =>
                  Swal.fire({
                    icon: 'error',
                    title: 'Login failed',
                    text: 'Something went wrong, please try again.',
                  })
                }
                text="continue_with"
                auto_select
              />
              </Row>
            </Card.Body>
          </Card>
        </Row>
      </Container>
    </div>
  );
}

const styles = StyleSheet.create({
  login: {
    alignItems: "center",
    display: "flex",
    minHeight: "100vh"
  },
  loginCard: {
    width: "25rem"
  },
  imge: {
    color: "black",
    fill: "blue"
  },
  background: {
    backgroundImage: "url(" + background + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "100vh"
  }
});

export default Login;
