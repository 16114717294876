import React, { useEffect, useState, useMemo } from "react";

import { Button, Card, Container, Table } from "react-bootstrap";
import classnames from "classnames";
import { css, StyleSheet } from "aphrodite";
import dayjs from "dayjs";
import { sumBy } from "lodash-es";
import Swal from "sweetalert2";
import { useHistory, useLocation, useParams } from "react-router-dom";

import AppNavBar from "components/layout/AppNavBar";
import {
  CLOUD_WATCH_URL,
  GET_SINGLE_ORDER_GUIDE_SCRAPE_DETAILS,
  SCRAPE_ERROR_PNG,
  SCRAPE_ERROR_FILE,
} from "config/config";
import LoadingSpinner from "shared/LoadingSpinner";
import Logout from "shared/Logout";
import { post } from "networking/http";
import { SwalToast } from "components/lib/ui/toast";

function OrderGuideScraperStatusView() {
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState();
  const params = useParams();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    setLoading(true);
    post(`${GET_SINGLE_ORDER_GUIDE_SCRAPE_DETAILS}${params?.status_key}`)
      .then((res) => {
        if (res) {
          setTableData(res.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err?.response?.data && err?.response.status === 401) {
          SwalToast(
            "success",
            `Token is not valid : ${err.response.data.message}`
          );
          Logout(history, location);
        } else if (err?.response?.data) {
          Swal.fire("Something Went Wrong", err.response.data.message, "error");
        } else {
          Swal.fire("Something Went Wrong", err.message, "error");
        }
      });
  }, []);

  const cloudWatchURL = useMemo(() => {
    const startTime = dayjs(tableData?.time).hour(-24).format();
    const endTime = dayjs(tableData?.time).hour(24).format();
    return (
      CLOUD_WATCH_URL +
      tableData?.status_key +
      "%22;start=" +
      startTime +
      ";end=" +
      endTime
    );
  }, [tableData]);

  const totalItems = useMemo(() => {
    if (tableData?.status === "successful")
      return sumBy(
        tableData?.scrape_details.map((e) => e),
        "total_items"
      );
  }, [tableData]);

  return (
    <div>
      <AppNavBar header="Order Guide Scraper" />
      <If condition={loading}>
        <LoadingSpinner />
      </If>
      <Choose>
        <When condition={tableData?.status === "unsuccessful"}>
          <Container
            className={classnames("d-flex flex-column", css(styles.container))}
          >
            <h1 className={css(styles.header)}>{"Scrape Details"}</h1>
            <Table
              striped
              bordered
              hover
              className={classnames("text-center", css(styles.table))}
            >
              <thead>
                <tr>
                  <th>{"Vendor Id"}</th>
                  <th>{"Operator Id"}</th>
                  <th>{"Status Key"}</th>
                  <th>{"Status"}</th>
                  <th>{"Cloud Watch Url"}</th>
                  <th>{"Error HTML"}</th>
                  <th>{"Error SS"}</th>
                </tr>
              </thead>
              <tbody>
                <tr className={css(styles.tableRowDanger)}>
                  <td>{tableData.vendor_id}</td>
                  <td>{tableData.operator_id}</td>
                  <td>{tableData.status_key}</td>
                  <td>{tableData.status}</td>
                  <td>
                    <a href={cloudWatchURL} target="_blank">
                      {"cloud watch url"}
                    </a>
                  </td>
                  <td>
                    <a
                      href={SCRAPE_ERROR_FILE + tableData?.status_key + ".html"}
                      target="_blank"
                    >
                      {"Error HTML url"}
                    </a>
                  </td>
                  <td>
                    <a
                      href={SCRAPE_ERROR_PNG + tableData?.status_key + ".png"}
                      target="_blank"
                    >
                      {"Error SS url"}
                    </a>
                  </td>
                </tr>
              </tbody>
            </Table>
            <Card style={{ width: "20rem" }}>
              <a
                href={SCRAPE_ERROR_PNG + tableData?.status_key + ".png"}
                target="_blank"
              >
                <Card.Img
                  src={SCRAPE_ERROR_PNG + tableData?.status_key + ".png"}
                  variant="top"
                />
              </a>
              <Card.Body>
                <Card.Title>{"Error Message"}</Card.Title>
                <Card.Text>{tableData?.message}</Card.Text>
              </Card.Body>
              <Card.Footer>
                <small className="text-muted">{tableData?.time}</small>
              </Card.Footer>
            </Card>
          </Container>
        </When>
        <When condition={tableData?.status === "progress"}>
          <Container
            className={classnames("d-flex flex-column", css(styles.container))}
          >
            <h1 className={css(styles.header)}>{"Scrape Details"}</h1>
            <Table
              striped
              bordered
              hover
              className={classnames("text-center", css(styles.table))}
            >
              <thead>
                <tr>
                  <th>{"Vendor Id"}</th>
                  <th>{"Operator Id"}</th>
                  <th>{"Status Key"}</th>
                  <th>{"Status"}</th>
                  <th>{"Started Time"}</th>
                  <th>{"Cloud Watch Url"}</th>
                </tr>
              </thead>
              <tbody>
                <tr className={css(styles.tableRowProgress)}>
                  <td>{tableData.vendor_id}</td>
                  <td>{tableData.operator_id}</td>
                  <td>{tableData.status_key}</td>
                  <td>{tableData.status}</td>
                  <td>
                    {dayjs(tableData?.time.$date).format("YYYY-MM-DDTHH:mm:ss")}
                  </td>
                  <td>
                    <a href={cloudWatchURL} target="_blank">
                      {"cloud watch url"}
                    </a>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Container>
        </When>
        <When condition={tableData?.status === "successful"}>
          <Container
            className={classnames("d-flex flex-column", css(styles.container))}
          >
            <h1 className={css(styles.header)}>{"Scrape Details"}</h1>
            <Table
              striped
              bordered
              hover
              className={classnames("text-center", css(styles.table))}
            >
              <thead>
                <tr>
                  <th>{"Vendor Id"}</th>
                  <th>{"Operator Id"}</th>
                  <th>{"Status Key"}</th>
                  <th>{"Status"}</th>
                  <th>{"Started Time"}</th>
                  <th>{"Duration"}</th>
                  <th>{"OG Count"}</th>
                  <th>{"Total Items"}</th>
                  <th>{"Cloud Watch Url"}</th>
                </tr>
              </thead>
              <tbody>
                <tr className={css(styles.tableRowSuccess)}>
                  <td>{tableData?.vendor_id}</td>
                  <td>{tableData.operator_id}</td>
                  <td>{tableData?.status_key}</td>
                  <td>{tableData?.status}</td>
                  <td>
                    {dayjs(tableData?.time.$date).format("YYYY-MM-DDTHH:mm:ss")}
                  </td>
                  <td>
                    {tableData?.stats?.duration
                      ? parseFloat(tableData?.stats?.duration).toFixed(2) +
                        " secs"
                      : "-- mins"}
                  </td>
                  <td>{tableData?.scrape_details.length}</td>
                  <td>{totalItems}</td>
                  <td>
                    <a href={cloudWatchURL} target="_blank">
                      {"cloud watch url"}
                    </a>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Container>
          <Container
            className={classnames(
              "d-flex flex-column",
              css(styles.subContainer)
            )}
          >
            <h1 className={css(styles.header)}>{"Scraped Order Guides"}</h1>
            <Table
              striped
              bordered
              hover
              className={classnames("text-center", css(styles.table))}
            >
              <thead>
                <tr>
                  <th>{"OG Name"}</th>
                  <th>{"Total Line Items"}</th>
                  <th>{"Order Guide Line Items"}</th>
                </tr>
              </thead>
              <tbody>
                <For
                  each="orderGuide"
                  index="index"
                  of={tableData?.scrape_details}
                >
                  <tr key={index} className={css(styles.tableRowSuccess)}>
                    <td>{orderGuide.order_guide_name}</td>
                    <td>{orderGuide.total_items}</td>
                    <td
                      onClick={
                        () =>
                          window.open(
                            `/dashboard/orderguide-scraper/object_id/${tableData?.scrape_details?.[index]._id.$oid}`
                          )
                        // history.push({pathname: `/dashboard/orderguide-scraper/object_id/${tableData?.scrape_details?.[index]._id.$oid}`})
                      }
                    >
                      <Button variant="link">Click For Details</Button>
                    </td>
                  </tr>
                </For>
              </tbody>
            </Table>
          </Container>
        </When>
      </Choose>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    maxWidth: "90%",
    justifyContent: "center",
    marginLeft: "0rem",
    overflowX: "auto",
  },
  subContainer: {
    marginTop: "10rem",
    maxWidth: "40%",
    justifyContent: "center",
    marginLeft: "0rem",
  },
  table: {
    marginTop: 15,
  },
  tableRowDanger: {
    background: "#efdfdf",
  },
  tableRowSuccess: {
    background: "#e2efda",
  },
  tableRowProgress: {
    background: "#eec780",
  },
  header: {
    fontFamily: "Orbitron",
    fontSize: 25,
    letterSpacing: 1,
    justifyContent: "center",
    marginLeft: "2rem",
    marginTop: "2rem",
  },
});

export default OrderGuideScraperStatusView;
