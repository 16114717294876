import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Container, Table } from "react-bootstrap";
import { css, StyleSheet } from "aphrodite";
import classnames from "classnames";
import dayjs from "dayjs";

const KEYS_TO_IGNORE = ["images", "items", "location", "ship_date"];
function InvoiceScraperScrapedDataView({ data }) {
  const [filtered, setFiltered] = useState({});

  useEffect(() => {
    const newObject = Object.keys(data)
      .filter(key => !KEYS_TO_IGNORE.includes(key))
      .reduce((obj, key) => {
        key === "order_date"
          ? obj[key] = dayjs(data[key].$date).format("YYYY-MM-DDTHH:mm:ss")
          : obj[key] = data[key];
        return obj;
      }, {});
    setFiltered(newObject);
  }, []);

  return (
    <div>
      <Container
        className={classnames("d-flex flex-column mt-5", css(styles.container))}
      >
        <Table className="text-center">
          <tbody>
            <For each="header" of={Object.keys(filtered)}>
              <tr key={header}>
                <td className={css(styles.head)}>{header}</td>
                <td>{filtered[header]}</td>
              </tr>
            </For>
          </tbody>
        </Table>
      </Container>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    maxWidth: "40%",
    justifyContent: "center"
  },
  head: {
    backgroundColor: "#B4D5DE"
  }
});

InvoiceScraperScrapedDataView.propTypes = {
  data: PropTypes.object
};

export default InvoiceScraperScrapedDataView;
