import React, { useEffect, useState } from "react";

import { Container, Table } from "react-bootstrap";
import classNames from "classnames";
import { css, StyleSheet } from "aphrodite";
import { useHistory, useLocation } from "react-router-dom";
import Swal from "sweetalert2";

import AppNavBar from "components/layout/AppNavBar";
import CatalogScraperRow from "components/scraper/Catalog/CatalogScraperRow";
import { get } from "networking/http";
import { GET_CATELOG_DETAILS } from "config/config";
import { lg } from "shared/screen";
import LoadingSpinner from "shared/LoadingSpinner";
import Logout from "shared/Logout";
import { SwalToast } from "components/lib/ui/toast";

function Catalogs() {
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    setLoading(true);
    get(`${GET_CATELOG_DETAILS}`)
      .then(res => {
        if (res) {
          setTableData(res.data);
          setLoading(false);
        }
      })
      .catch(err => {
        setLoading(false);
        if (err?.response?.data && err?.response.status === 401) {
          SwalToast(
            "success",
            `Token is not valid : ${err.response.data.message}`
          );
          Logout(history, location);
        } else if (err?.response?.data) {
          Swal.fire("Something Went Wrong", err.response.data.message, "error");
        } else {
          Swal.fire("Something Went Wrong", err.message, "error");
        }
      });
  }, []);

  return (
    <div>
      <AppNavBar header="Catalogs" />
      <Container
        className={classNames(css(styles.container), "d-flex flex-column")}
      >
        <Table
          striped
          bordered
          hover
          className={classNames("text-center", css(styles.table))}
        >
          <thead>
            <tr>
              <th>#</th>
              <th>{"Vendor"}</th>
              <th>{"Item Count"}</th>
              <th>{"Phased Out Items Count"}</th>
              <th>{"Last Updated Time"}</th>
              <th>{"Last Synced Time"}</th>
              <th>{"More Details"}</th>
            </tr>
          </thead>
          <tbody>
            <For each="VendorDetails" index="index" of={tableData}>
              <CatalogScraperRow
                index={index}
                VendorDetails={VendorDetails}
                key={index}
              />
            </For>
          </tbody>
        </Table>
        <If condition={loading}>
          <LoadingSpinner />
        </If>
      </Container>
    </div>
  );
}

const styles = StyleSheet.create({
  table: {
    marginTop: 15
  },
  container: {
    maxWidth: lg() ? "80%" : "100%",
    overflowX: "auto"
  }
});

export default Catalogs;
