import React, { useMemo, useState, useEffect } from "react";

import { Button, Col, Form, Row } from "react-bootstrap";
import { css, StyleSheet } from "aphrodite";
import DataTable from "react-data-table-component";
import Select from "react-select";
import Swal from "sweetalert2";
import { useHistory, useLocation, useParams } from "react-router";

import AppNavBar from "components/layout/AppNavBar";
import { get } from "networking/http";
import { GET_CATELOG_FOR_VENDOR } from "config/config";
import LoadingSpinner from "shared/LoadingSpinner";
import Logout from "shared/Logout";
import PaginationBar from "shared/PaginationBar";
import SearchBar from "components/scraper/Catalog/SearchBar";
import { SwalToast } from "components/lib/ui/toast";

const paramMapping = {
  item_code: "itemcode",
  product_name: "itemname"
};

function CatalogScraperVendorView() {
  const [tableData, setTableData] = useState();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const params = useParams();
  const location = useLocation();

  const [page, setPage] = useState(parseInt((params.page - 1) * 10));
  const [activePage, setActivePage] = useState(parseInt(params.page));

  const [selectedOption, setSelectedOption] = useState(() => {
    const temp_params = { ...params };
    delete temp_params.page;
    delete temp_params.vendor_id;
    return Object.values(temp_params)[0]
      ? {
          value: Object.keys(temp_params)[0],
          label: Object.keys(temp_params)[0]
        }
      : [];
  });

  const [searchValue, setSearchValue] = useState(() => {
    const temp_params = { ...params };
    delete temp_params.page;
    delete temp_params.vendor_id;
    return Object.values(temp_params)[0] ? Object.values(temp_params)[0] : "";
  });

  useEffect(() => {
    if (params?.vendor_id) {
      const temp_params = { ...params };
      delete temp_params.page;
      delete temp_params.vendor_id;
      const query = searchValue
        ? `${GET_CATELOG_FOR_VENDOR}${params?.vendor_id}&${
            Object.keys(temp_params)[0]
          }=${Object.values(temp_params)[0]}&offset=${page}`
        : `${GET_CATELOG_FOR_VENDOR}${params?.vendor_id}&offset=${page}`;
      console.log("query", query);
      setLoading(true);
      get(query)
        .then(res => {
          if (res) {
            setTableData(res.data);
            setLoading(false);
          }
        })
        .catch(err => {
          setLoading(false);
          if (err?.response?.data && err?.response.status === 401) {
            SwalToast(
              "success",
              `Token is not valid : ${err.response.data.message}`
            );
            Logout(history, location);
          } else if (err?.response?.data) {
            Swal.fire(
              "Something Went Wrong",
              err.response.data.message,
              "error"
            );
          } else {
            Swal.fire("Something Went Wrong", err.message, "error");
          }
        });
    }
  }, [params?.vendor_id, activePage, params.item_code]);

  const Details = ({ label, row }) => (
    <Button
      variant="link"
      onClick={() => {
        window.open(
          `/dashboard/catalog-scraper/vendor/${params?.vendor_id}/item/${row.item_code}`
        );
        // history.push({pathname: `/dashboard/catalog-scraper/vendor/${params?.vendor_id}/item/${row.item_code}`})
      }}
    >
      {label}
    </Button>
  );

  const columns = useMemo(
    () => [
      {
        name: "Item Code",
        selector: "item_code",
        sortable: true
      },
      {
        name: "Product Name",
        cell: row => (
          <Details label={row.product_name} row={row}>
            Action
          </Details>
        ),
        allowOverflow: true,
        button: true,
        minWidth: "350px"
      },
      {
        name: "Category",
        selector: "category",
        sortable: true
      },
      {
        name: "UOM",
        selector: "uom",
        sortable: true
      },
      {
        name: "Unit Price",
        selector: "unit_price",
        sortable: true
      },
      {
        name: "Details",
        cell: row => (
          <Details label={"click for details"} row={row}>
            Action
          </Details>
        ),
        allowOverflow: true,
        button: true,
        minWidth: "200px"
      }
    ],
    []
  );

  const customStyles = {
    rows: {
      style: {
        minHeight: "80px" // override the row height
      }
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px"
      }
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px"
      }
    }
  };

  function changeActivePage(page) {
    setActivePage(page);
    const temp_params = { ...params };
    delete temp_params.page;
    delete temp_params.vendor_id;
    if (Object.keys(temp_params).length > 0) {
      history.push({
        pathname: `/dashboard/catalog-scraper/vendor/${params?.vendor_id}/${
          paramMapping[Object.keys(temp_params)[0]]
        }/${Object.values(temp_params)[0]}/${page}`
      });
    } else {
      history.push({
        pathname: `/dashboard/catalog-scraper/vendor/${params?.vendor_id}/${page}`
      });
    }
  }

  var filtered = tableData?.catalog_items.filter(function(el) {
    return el != null;
  });

  return (
    <div>
      <AppNavBar header="Catalog Scraper Vendor View" />
      <SearchBar
        itemCount={tableData?.total_items_length || 0}
        latestTimestamp={tableData?.latest_updated_timestamp || 0}
        loading={loading}
      />
      <Form>
        <Row className={css(styles.searchBar)}>
          <Col sm="1" className={css(styles.label)}>
            <Select
              value={selectedOption}
              defaultOptions
              isClearable={true}
              options={[{ label: "Item Code", value: "item_code" }]}
              onChange={e => {
                setSelectedOption(e);
              }}
            ></Select>
          </Col>
          <Col sm="2" className={css(styles.input)}>
            <Form.Control
              size="md"
              type="text"
              placeholder={searchValue || selectedOption?.value}
              onChange={e => {
                setSearchValue(e.target.value);
              }}
            />
          </Col>
          <Col sm="1">
            <Button
              style={{ width: "100%" }}
              onClick={() => {
                setPage(0);
                history.push({
                  pathname: `/dashboard/catalog-scraper/vendor/${
                    params?.vendor_id
                  }/${paramMapping[selectedOption.value]}/${searchValue}/${1}`
                });
                setActivePage(1);
              }}
            >
              {"Search"}
            </Button>
          </Col>
          <Col sm="1">
            <Button
              style={{ width: "100%" }}
              onClick={() => {
                setPage(0);
                setSearchValue("");
                history.push({
                  pathname: `/dashboard/catalog-scraper/vendor/${
                    params?.vendor_id
                  }/${1}`
                });
                setActivePage(1);
              }}
            >
              {"Clear"}
            </Button>
          </Col>
        </Row>
      </Form>
      <PaginationBar
        activePage={activePage}
        itemsCount={tableData?.total_items_length || 0}
        offsetFunction={setPage}
        currentActivePage={changeActivePage}
      ></PaginationBar>
      <Choose>
        <When condition={loading}>
          <LoadingSpinner />
        </When>
      </Choose>
      <Choose>
        <When condition={loading}>
          <LoadingSpinner />
        </When>
        <When condition={tableData}>
          <DataTable
            columns={columns}
            customStyles={customStyles}
            data={filtered}
            fixedHeader={true}
          />
        </When>
      </Choose>
      <PaginationBar
        activePage={activePage}
        itemsCount={tableData?.total_items_length || 0}
        offsetFunction={setPage}
        currentActivePage={changeActivePage}
      ></PaginationBar>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    maxWidth: "80%"
  },
  table: {
    marginTop: 25
  },
  searchBar: {
    justifyContent: "center",
    paddingTop: "3rem"
  },
  label: {
    paddingRight: "0rem",
    textAlign: "right"
  },
  input: {
    paddingLeft: "1rem",
    paddingRight: "0rem"
  },
  clear: {
    float: "right",
    paddingLeft: "0rem",
    height: "3rem",
    maxWidth: "1rem"
  }
});

export default CatalogScraperVendorView;
