import React, { useEffect, useMemo, useState } from "react";

import { Card, Container, Row, Tab, Table, Tabs } from "react-bootstrap";
import classnames from "classnames";
import { css, StyleSheet } from "aphrodite";
import dayjs from "dayjs";
import { sumBy } from "lodash-es";
import Swal from "sweetalert2";
import { useHistory, useLocation, useParams } from "react-router-dom";

import AppNavBar from "components/layout/AppNavBar";
import {
  CLOUD_WATCH_URL,
  GET_ORDER_PLACEMENTS,
  ORDER_CONFIRMATION,
  ORDER_FILLED,
  ORDER_DELIVERY_DATES,
  ORDER_ERROR_PNG,
  ORDER_ERROR_FILE
} from "config/config";
import { get } from "networking/http";
import LoadingSpinner from "shared/LoadingSpinner";
import Logout from "shared/Logout";
import OrderPlacementStatsView from "components/scraper/OrderPlacements/OrderPlacementStatsView";
import OrderPlacementRequestView from "components/scraper/OrderPlacements/OrderPlacementRequestView";
import { SwalToast } from "components/lib/ui/toast";

function OrderPlacementStatusView() {
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState();
  const params = useParams();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    setLoading(true);
    get(`${GET_ORDER_PLACEMENTS}?status_key=${params.status_key}&offset=0`)
      .then(res => {
        if (res) {
          setTableData(res.data[0]);
          setLoading(false);
        }
      })
      .catch(err => {
        setLoading(false);
        if (err?.response?.data && err?.response.status === 401) {
          SwalToast(
            "success",
            `Token is not valid : ${err.response.data.message}`
          );
          Logout(history, location);
        } else if (err?.response?.data) {
          Swal.fire("Something Went Wrong", err.response.data.message, "error");
        } else {
          Swal.fire("Something Went Wrong", err.message, "error");
        }
      });
  }, []);

  const cloudWatchURL = useMemo(() => {
    const endTime = dayjs(tableData?.time)
      .hour(24)
      .format();
    const startTime = dayjs(tableData?.time)
      .hour(-24)
      .format();
    return (
      CLOUD_WATCH_URL +
      tableData?.status_key +
      "%22;start=" +
      startTime +
      ";end=" +
      endTime
    );
  }, [tableData]);

  const totalItems = useMemo(() => {
    if (tableData?.status === "successful")
      return sumBy(
        tableData?.scrape_details.map(e => e),
        "total_items"
      );
  }, [tableData]);

  return (
    <div>
      <AppNavBar header="Order Placement Status View" />
      <If condition={loading}>
        <LoadingSpinner />
      </If>
      <Tabs
        defaultActiveKey="main"
        id="uncontrolled-tab-example"
        className={css(styles.tab)}
      >
        <Tab eventKey="main" title="Order Placement Details">
          <Choose>
            <When condition={tableData?.status === "unsuccessful"}>
              <Container
                className={classnames(
                  "d-flex flex-column",
                  css(styles.container)
                )}
              >
                <h1 className={css(styles.header)}>{"Scrape Details"}</h1>
                <Table
                  striped
                  bordered
                  hover
                  className={classnames("text-center", css(styles.table))}
                >
                  <thead>
                    <tr>
                      <th>{"Vendor Id"}</th>
                      <th>{"Status Key"}</th>
                      <th>{"Status"}</th>
                      <th>{"Duration (sec)"}</th>
                      <th>{"Operator Id"}</th>
                      <th>{"Order Id"}</th>
                      <th>{"Requested Date"}</th>
                      <th>{"Shipped Date"}</th>
                      <th>{"Cloud Watch Url"}</th>
                      <th>{"Error HTML"}</th>
                      <th>{"Error SS"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className={css(styles.tableRowDanger)}>
                      <td>{tableData.vendor_id}</td>
                      <td>{tableData.status_key}</td>
                      <td>{tableData.status}</td>
                      <td>{tableData.stats?.duration || "-"}</td>
                      <td>{tableData.operator_id}</td>
                      <td>{tableData.cd_order_id}</td>
                      <td>{tableData.requested_date}</td>
                      <td>{tableData.ship_date}</td>
                      <td>
                        <a href={cloudWatchURL} target="_blank">
                          {"cloud watch url"}
                        </a>
                      </td>
                      <td>
                        <a
                          href={
                            ORDER_ERROR_FILE + tableData?.status_key + ".html"
                          }
                          target="_blank"
                        >
                          {"Error HTML url"}
                        </a>
                      </td>
                      <td>
                        <a
                          href={
                            ORDER_ERROR_PNG + tableData?.status_key + ".png"
                          }
                          target="_blank"
                        >
                          {"Error SS url"}
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <Card style={{ width: "20rem" }}>
                  <a
                    href={ORDER_ERROR_PNG + tableData?.status_key + ".png"}
                    target="_blank"
                  >
                    <Card.Img
                      src={ORDER_ERROR_PNG + tableData?.status_key + ".png"}
                      variant="top"
                    />
                  </a>
                  <Card.Body>
                    <Card.Title>{"Error Message"}</Card.Title>
                    <Card.Text>{tableData?.message}</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <small className="text-muted">{tableData?.time}</small>
                  </Card.Footer>
                </Card>
              </Container>
            </When>
            <When condition={tableData?.status === "draft_saved"}>
              <Container
                className={classnames(
                  "d-flex flex-column",
                  css(styles.container)
                )}
              >
                <h1 className={css(styles.header)}>{"Scrape Details"}</h1>
                <Table
                  striped
                  bordered
                  hover
                  className={classnames("text-center", css(styles.table))}
                >
                  <thead>
                    <tr>
                      <th>{"Vendor Id"}</th>
                      <th>{"Status Key"}</th>
                      <th>{"Status"}</th>
                      <th>{"Duration (sec)"}</th>
                      <th>{"Operator Id"}</th>
                      <th>{"Order Id"}</th>
                      <th>{"Requested Date"}</th>
                      <th>{"Shipped Date"}</th>
                      <th>{"Cloud Watch Url"}</th>
                      <th>{"Error HTML"}</th>
                      <th>{"Error SS"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className={css(styles.tableRowDraftOrder)}>
                      <td>{tableData.vendor_id}</td>
                      <td>{tableData.status_key}</td>
                      <td>{tableData.status}</td>
                      <td>{tableData.stats?.duration || "-"}</td>
                      <td>{tableData.operator_id}</td>
                      <td>{tableData.cd_order_id}</td>
                      <td>{tableData.requested_date}</td>
                      <td>{tableData.ship_date}</td>
                      <td>
                        <a href={cloudWatchURL} target="_blank">
                          {"cloud watch url"}
                        </a>
                      </td>
                      <td>
                        <a
                          href={
                            ORDER_ERROR_FILE + tableData?.status_key + ".html"
                          }
                          target="_blank"
                        >
                          {"Error HTML url"}
                        </a>
                      </td>
                      <td>
                        <a
                          href={
                            ORDER_ERROR_PNG + tableData?.status_key + ".png"
                          }
                          target="_blank"
                        >
                          {"Error SS url"}
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <Row>
                  <Card className={css(styles.cards)}>
                    <a
                      href={ORDER_FILLED + tableData?.status_key + ".png"}
                      target="_blank"
                    >
                      <Card.Img
                        src={ORDER_FILLED + tableData?.status_key + ".png"}
                        variant="top"
                      />
                    </a>
                    <Card.Body>
                      <Card.Title>{"Order Filled Page"}</Card.Title>
                      <Card.Text>{tableData?.message}</Card.Text>
                    </Card.Body>
                    <Card.Footer>
                      <small className="text-muted">{tableData?.time}</small>
                    </Card.Footer>
                  </Card>
                  <Card className={css(styles.cards)}>
                    <a
                      href={
                        ORDER_DELIVERY_DATES + tableData?.status_key + ".png"
                      }
                      target="_blank"
                    >
                      <Card.Img
                        src={
                          ORDER_DELIVERY_DATES + tableData?.status_key + ".png"
                        }
                        variant="top"
                      />
                    </a>
                    <Card.Body>
                      <Card.Title>{"Order Delivery Dates Page"}</Card.Title>
                      <Card.Text>{tableData?.message}</Card.Text>
                    </Card.Body>
                    <Card.Footer>
                      <small className="text-muted">{tableData?.time}</small>
                    </Card.Footer>
                  </Card>
                  <Card className={css(styles.cards)}>
                    <a
                      href={ORDER_ERROR_PNG + tableData?.status_key + ".png"}
                      target="_blank"
                    >
                      <Card.Img
                        src={ORDER_ERROR_PNG + tableData?.status_key + ".png"}
                        variant="top"
                      />
                    </a>
                    <Card.Body>
                      <Card.Title>{"Error Message"}</Card.Title>
                      <Card.Text>{tableData?.message}</Card.Text>
                    </Card.Body>
                    <Card.Footer>
                      <small className="text-muted">{tableData?.time}</small>
                    </Card.Footer>
                  </Card>
                </Row>
              </Container>
            </When>
            <When condition={tableData?.status === "order_submitted"}>
              <Container
                className={classnames(
                  "d-flex flex-column",
                  css(styles.container)
                )}
              >
                <h1 className={css(styles.header)}>
                  {"Order Placement Details"}
                </h1>
                <Table
                  striped
                  bordered
                  hover
                  className={classnames("text-center", css(styles.table))}
                >
                  <thead>
                    <tr>
                      <th>{"Vendor Id"}</th>
                      <th>{"Status Key"}</th>
                      <th>{"Status"}</th>
                      <th>{"Operator Id"}</th>
                      <th>{"Order Id"}</th>
                      <th>{"Requested Date"}</th>
                      <th>{"Shipped Date"}</th>
                      <th>{"Started Time"}</th>
                      <th>{"Duration (sec)"}</th>
                      <th>{"Cloud Watch Url"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className={css(styles.tableRowSuccess)}>
                      <td>{tableData?.vendor_id}</td>
                      <td>{tableData?.status_key}</td>
                      <td>{tableData?.status}</td>
                      <td>{tableData?.operator_id}</td>
                      <td>{tableData?.cd_order_id}</td>
                      <td>{tableData.requested_date}</td>
                      <td>{tableData.ship_date}</td>
                      <td>
                        {dayjs(tableData?.time.$date).format(
                          "YYYY-MM-DDTHH:mm:ss"
                        )}
                      </td>
                      <td>{tableData.stats?.duration || "-"}</td>
                      <td>
                        <a href={cloudWatchURL} target="_blank">
                          {"cloud watch url"}
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <Row>
                  <Card className={css(styles.cards)}>
                    <a
                      href={ORDER_CONFIRMATION + tableData?.status_key + ".png"}
                      target="_blank"
                    >
                      <Card.Img
                        src={
                          ORDER_CONFIRMATION + tableData?.status_key + ".png"
                        }
                        variant="top"
                      />
                    </a>
                    <Card.Body>
                      <Card.Title>{"Order Confirmation Page"}</Card.Title>
                      <Card.Text>{tableData?.message}</Card.Text>
                    </Card.Body>
                    <Card.Footer>
                      <small className="text-muted">{tableData?.time}</small>
                    </Card.Footer>
                  </Card>
                  <Card className={css(styles.cards)}>
                    <a
                      href={ORDER_FILLED + tableData?.status_key + ".png"}
                      target="_blank"
                    >
                      <Card.Img
                        src={ORDER_FILLED + tableData?.status_key + ".png"}
                        variant="top"
                      />
                    </a>
                    <Card.Body>
                      <Card.Title>{"Order Filled Page"}</Card.Title>
                      <Card.Text>{tableData?.message}</Card.Text>
                    </Card.Body>
                    <Card.Footer>
                      <small className="text-muted">{tableData?.time}</small>
                    </Card.Footer>
                  </Card>
                  <Card className={css(styles.cards)}>
                    <a
                      href={
                        ORDER_DELIVERY_DATES + tableData?.status_key + ".png"
                      }
                      target="_blank"
                    >
                      <Card.Img
                        src={
                          ORDER_DELIVERY_DATES + tableData?.status_key + ".png"
                        }
                        variant="top"
                      />
                    </a>
                    <Card.Body>
                      <Card.Title>{"Order Delivery Dates Page"}</Card.Title>
                      <Card.Text>{tableData?.message}</Card.Text>
                    </Card.Body>
                    <Card.Footer>
                      <small className="text-muted">{tableData?.time}</small>
                    </Card.Footer>
                  </Card>
                </Row>
              </Container>
            </When>
          </Choose>
        </Tab>
        <If condition={tableData?.request_data}>
          <Tab eventKey="request" title="Order Request Details">
            <OrderPlacementRequestView
              data={tableData?.request_data}
            ></OrderPlacementRequestView>
          </Tab>
        </If>
        <If condition={tableData?.order_stats}>
          <Tab eventKey="stats" title="Order Confirmation Stat Details">
            <OrderPlacementStatsView
              stats={tableData?.order_stats}
              exceptions={tableData?.exception_items || []}
            ></OrderPlacementStatsView>
          </Tab>
        </If>
      </Tabs>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    maxWidth: "90%",
    justifyContent: "center",
    marginLeft: "0rem",
    overflowX: "auto"
  },
  subContainer: {
    marginTop: "10rem",
    maxWidth: "40%",
    justifyContent: "center",
    marginLeft: "0rem"
  },
  table: {
    marginTop: 15
  },
  tableRowDanger: {
    background: "#efdfdf"
  },
  tableRowSuccess: {
    background: "#e2efda"
  },
  tableRowDraftOrder: {
    background: "#eec780"
  },
  header: {
    fontFamily: "Orbitron",
    fontSize: 25,
    letterSpacing: 1,
    justifyContent: "center",
    marginLeft: "2rem",
    marginTop: "2rem"
  },
  cards: {
    width: "20rem",
    margin: "1rem"
  },
  tab: {
    marginTop: "2rem"
  }
});

export default OrderPlacementStatusView;
