import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { Container } from "react-bootstrap";
import DataTable from "react-data-table-component";

function ScreensTable({ rows }) {
  const columns = useMemo(() => [
    {
      name: "Screen ID",
      selector: "screen_id",
      sortable: true
    },
    {
      name: "Screen Name",
      selector: "screen_name",
      sortable: true
    },
    {
      name: "Started Date",
      selector: "started_date",
      sortable: true
    },
    {
      name: "Started Time",
      selector: "started_time",
      sortable: true
    }
  ]);
  return (
    <Container>
      <DataTable data={rows} columns={columns} fixedHeader={true} />
    </Container>
  );
}

ScreensTable.propTypes = {
  rows: PropTypes.array
};

export default ScreensTable;
