import React, { useState } from "react";

import { Col, Container, Button, Form, Row } from "react-bootstrap";
import classNames from "classnames";
import { css, StyleSheet } from "aphrodite";
import Select from "react-select";
import Swal from "sweetalert2";
import { SwalToast } from "components/lib/ui/toast";
import { useHistory, useLocation } from "react-router";

import AdditionalParametersSection from "components/catalogsRun/AdditionalParametersSection";
import AppNavBar from "components/layout/AppNavBar";
import { CATALOG_SCRAPES } from "config/config";
import {
  GET_SCREENS,
  POST_CREATE_A_SCREEN,
  POST_DELETE_A_SCREEN
} from "config/config";
import { get, post } from "networking/http";
import { lg } from "shared/screen";
import LoadingSpinner from "shared/LoadingSpinner";
import Logout from "shared/Logout";
import ScreensTable from "components/catalogsRun/ScreensTable";

function CatalogRuns() {
  const history = useHistory();
  const location = useLocation();

  const [customerCredentials, setCustomerCredentials] = useState({
    userName: "",
    password: "",
    setValues: true
  });
  const [scrapeVendor, setScrapeVendor] = useState("");
  const [additionalParameters, setAdditionalParameters] = useState({});
  const [screenToDelete, setScreenToDelete] = useState("");
  const [screenName, setScreenName] = useState();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [resume, setResume] = useState(false);
  function getScreens() {
    setLoading(true);
    get(`${GET_SCREENS}`)
      .then(res => {
        if (res) {
          setData(res.data);
          setLoading(false);
        }
      })
      .catch(err => {
        setLoading(false);
        if (err?.response?.data && err?.response.status === 401) {
          SwalToast(
            "success",
            `Token is not valid : ${err.response.data.message}`
          );
          Logout(history, location);
        } else if (err?.response?.data) {
          Swal.fire("Something Went Wrong", err.response.data.message, "error");
        } else {
          Swal.fire("Something Went Wrong", err.message, "error");
        }
      });
  }

  function deleteAScreen() {
    Swal.fire({
      title: "Are you sure you want to delete the screen?",
      showCancelButton: true,
      confirmButtonText: `Yes`
    }).then(result => {
      if (result.isConfirmed) {
        setLoading(true);
        post(POST_DELETE_A_SCREEN, {
          screen_name: screenToDelete
        })
          .then(res => {
            if (res) {
              SwalToast(
                "success",
                `Succesfully Deleted the screen ${screenToDelete}`
              );
              setLoading(false);
            }
          })
          .catch(err => {
            setLoading(false);
            if (err?.response?.data) {
              Swal.fire(
                "Something Went Wrong",
                JSON.stringify(err.response.data.error),
                "error"
              );
            } else {
              Swal.fire("Login Failed!", "Please Try Again", "error");
            }
          });
      }
    });
  }

  function createAScreen() {
    if (!scrapeVendor) {
      Swal.fire("Please select a vendor scrape", "Info Missing", "error");
    } else if (!screenName) {
      Swal.fire("Please add a screen name", "Info Missing", "error");
    } else {
      setLoading(true);
      post(POST_CREATE_A_SCREEN, {
        screen_name: screenName,
        catalog_scrape_name: scrapeVendor?.label,
        username: customerCredentials.userName,
        password: customerCredentials.password,
        additionalParameters: additionalParameters,
        resume: resume
      })
        .then(res => {
          if (res) {
            SwalToast("success", "Successfully created the catalog job");
            setLoading(false);
          }
        })
        .catch(err => {
          setLoading(false);
          if (err?.response?.data) {
            Swal.fire(
              "Something Went Wrong",
              JSON.stringify(err.response.data.error),
              "error"
            );
          } else {
            Swal.fire("Login Failed!", "Please Try Again", "error");
          }
        });
    }
  }

  return (
    <>
      <AppNavBar header="Create A Catalog Scrape" />
      <Row className="mt-5 mb-3">
        <Col md={6}>
          <Container
            className={classNames(
              css(styles.container),
              "shadow-sm border d-flex flex-column"
            )}
          >
            <Form.Label className="font-weight-bold mt-1">
              {"Adding A New Screen"}
            </Form.Label>
            <Row className={classNames(css(styles.boxBackground), "mt-3")}>
              <Col xs={12} md={6}>
                <Select
                  value={scrapeVendor}
                  placeholder={"Select A Vendor"}
                  options={CATALOG_SCRAPES}
                  onChange={setScrapeVendor}
                ></Select>
              </Col>
              <Col xs={12} md={6}>
                <Form.Control
                  type="text"
                  placeholder={"Screen Name"}
                  value={screenName || ""}
                  onChange={e => {
                    setScreenName(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xs={12} md={4}>
                <Form.Check
                  className="mh-100"
                  type="checkbox"
                  checked={customerCredentials.setValues}
                  onChange={() =>
                    setCustomerCredentials({
                      ...customerCredentials,
                      setValues: !customerCredentials.setValues
                    })
                  }
                  label="Use Custom Credentials"
                />
              </Col>
              <Choose>
                <When
                  condition={customerCredentials.setValues}
                  className="mt-2"
                >
                  <Col xs={6} md={4} className="pl-2">
                    <Form.Control
                      type="text"
                      placeholder={"user name"}
                      value={customerCredentials.userName}
                      onChange={e => {
                        setCustomerCredentials({
                          ...customerCredentials,
                          userName: e.target.value
                        });
                      }}
                    />
                  </Col>
                  <Col xs={6} md={4}>
                    <Form.Control
                      type="text"
                      placeholder={"user password"}
                      value={customerCredentials.password}
                      onChange={e => {
                        setCustomerCredentials({
                          ...customerCredentials,
                          password: e.target.value
                        });
                      }}
                    />
                  </Col>
                </When>
              </Choose>
            </Row>
            <AdditionalParametersSection
              vendorName={scrapeVendor.label}
              additionalParameters={additionalParameters}
              setAdditionalParameters={setAdditionalParameters}
            />
            <Row className="mt-4">
              <Col xs={4} md={1}>
                <Form.Check
                  label="Resume"
                  type="checkbox"
                  checked={resume}
                  onChange={() => setResume(!resume)}
                />
              </Col>
            </Row>
            <Row className="mt-4 mb-3">
              <Col xs={12} md={4}>
                <Button block onClick={createAScreen}>
                  {"Add A New Catalog Scrape"}
                </Button>
              </Col>
            </Row>
          </Container>
        </Col>
        <Col className="mh-25 overflowY">
          <Container
            className={classNames(
              css(styles.tableContainer),
              "shadow-sm border d-flex flex-column"
            )}
          >
            <Form.Label className="font-weight-bold mt-1">
              {"View All The Screens"}
            </Form.Label>
            <Row className="mt-4">
              <Col xs={12} md={3}>
                <Button block onClick={getScreens} variant="info">
                  {"View The Active Screens"}
                </Button>
              </Col>
            </Row>
            <If condition={loading}>
              <LoadingSpinner />
            </If>
            <If condition={data.length}>
              <ScreensTable rows={data} />
            </If>
          </Container>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Container
            className={classNames(
              css(styles.container),
              "shadow-sm border d-flex flex-column"
            )}
          >
            <Form.Label className="font-weight-bold mt-1">
              {"Delete A Screen"}
            </Form.Label>
            <Row className="mt-4 mb-3">
              <Col xs={12} md={8}>
                <Form.Control
                  className="mt-1"
                  type="text"
                  placeholder={"Screen To Delete"}
                  value={screenToDelete}
                  onChange={e => {
                    setScreenToDelete(e.target.value);
                  }}
                />
              </Col>
              <Col xs={12} md={4}>
                <Button
                  className="mt-1"
                  block
                  variant={"warning"}
                  disabled={!screenToDelete}
                  onClick={deleteAScreen}
                >
                  {"Delete The Screen"}
                </Button>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    maxWidth: "100%",
    overflowX: "auto"
  },
  tableContainer: {
    maxWidth: "100%",
    overflowX: "auto",
    height: "340px"
  },
  boxBackground: {
    backgroundColor: "white"
  }
});

export default CatalogRuns;
