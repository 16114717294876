import React from "react";

import AppNavBar from "components/layout/AppNavBar";

function Home() {
  return (
    <div>
      <AppNavBar header="Integration Tests" />
      <p>Integration Tests page under construction</p>
    </div>
  );
}

export default Home;
