function useVendorMap() {
  const vendorMap = [
    { value: 1858737, label: "Sysco Shop", channel: "SYSCO_SHOP" },
    { value: 1858737, label: "Sysco" },
    { value: 1858759, label: "US Foods" },
    { value: 1862211, label: "Chef Warehouse" },
    { value: 1878393, label: "Restaurant Cheetah" },
    { value: 1843976, label: "PFG" },
    { value: 1843976, label: "PFG - Mobile App", channel: "MOBILE_API"},
    { value: 1848694, label: "Le Boulanger" },
    { value: 2135827, label: "West Central Food Service" },
    { value: 1861870, label: "Birite" },
    { value: 1861870, label: "Birite - Mobile App", channel: "MOBILE_API"},
    { value: 2091819, label: "P and R Paper" },
    { value: 3993346, label: "Freshpoint" },
    { value: 2488793, label: "Webstaurant store" },
    { value: 1976089, label: "A and B Produce" },
    { value: 1861839, label: "American Paper Provisions" },
    { value: 1862054, label: "Vesta" },
    { value: 1855962, label: "Southern Glazers" },
    { value: 1953584, label: "Restaurant Depot" },
    { value: 3267145, label: "Mike's Hot Honey" },
    { value: 1856053, label: "Daylight Foods" },
    { value: 1862153, label: "Reinhart Foodservice" },
    { value: 46432976, label: "Grasmick Produce" },
    { value: 1862232, label: "Van Eerden Foodservice" },
    { value: 1861985, label: "Ginsberg's Foods" },
    { value: 47955555, label: "Baldor Specialty Foods" },
    { value: 1862193, label: "Stanz Foodservice" },
    { value: 48526420, label: "GrubMarket" },
    { value: 1862179, label: "Shamrock Foods" },
    { value: 52498760, label: "Counter Culture" },
    { value: 63210716, label: "Kegels Produce" },
    { value: 2796590, label: "IFS" },
    { value: 1861888, label: "Casa Imports" },
    { value: 1862155, label: "Renzi Foodservice" },
  ];

  return vendorMap;
}

export default useVendorMap;
